import type { OverlayEventDetail } from '@ionic/core';
import { HubConnectionState, type HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import {
  ChainTypeEnum,
  MeetStatusEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MessageTypeEnum,
  MessengerChatTypeEnum,
} from '@/enums';
import { feedTypeHelper, isNativeMobile, showDismissingToast, toLastModel, useMeet, useNotifications } from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useChatStore,
  useMeetStore,
  useMessengerStore,
  useNotificationsStore,
  useUserStore,
} from '@/store';
import type {
  EditMessageModel,
  EventPrivateMessage,
  EventSiteNotification,
  EventUserTyping,
  MessageChainEntity,
  MessageEntity,
  MessagesReadModel,
  WebSocketModel,
} from '@/types';

export type IUseWebSockets = {
  typingEvents: Ref<EventUserTyping[]>;
  initWebSockets: (model: WebSocketModel | null) => Promise<void>;
  startWebSockets: () => Promise<boolean>;
  stopWebSockets: () => Promise<boolean>;
};

/* Singleton Instance of the useWebSockets helper */
let instance: IUseWebSockets | null = null;

/**
 * @description Helper for working with WebSockets
 */
export const useWebSockets = (): IUseWebSockets => {
  /**
   * @description Singleton instance of the useWebSockets helper
   * @returns IUseWebSockets if instance exists, otherwise creates a new instance
   */
  if (instance) {
    return instance;
  }

  //* Variables
  let connection: HubConnection | undefined;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isActive = false;
  const typingEvents = ref<EventUserTyping[]>([]);

  //* Stores
  const appStore = useAppStore();
  const chatStore = useChatStore();
  const meetStore = useMeetStore();
  const messengerStore = useMessengerStore();
  const userStore = useUserStore();
  const notificationsStore = useNotificationsStore();

  //* Helpers
  const { t } = useI18n();

  //* Private methods
  const _changeTyping = (data: EventUserTyping): void => {
    const index = typingEvents.value.findIndex(
      (f: EventUserTyping) => f.chainId === data.chainId && f.userId === data.userId
    );
    if (~index) {
      typingEvents.value.splice(index, 1, data);
    } else {
      typingEvents.value.push(data);
    }
  };

  const _onReconnected = async (connectionId?: string | undefined): Promise<void> => {
    try {
      if (!connectionId) return;

      const model = appStore.getWebSocketModel;
      if (!model) return;

      if (connection) {
        const invokeModel = {
          coreId: model.coreId,
          companyRowId: model.companyRowId,
          userId: model.userId,
          userRowId: model.userRowId,
        };
        await connection.invoke('connect', invokeModel);

        appStore.$patch((state) => {
          state.signalRConnectionId = connectionId;
          state.signalRConnectionStatus = connection?.state ?? HubConnectionState.Connected;
        });

        meetStore.$patch((state) => {
          state.connectionId = connectionId;
        });
      }

      return;
    } catch (e) {
      console.error('Reconnection failed', e);
      // If the connection is not established, show a toast with the ability to reconnect
      await _retryConnection();
    }
  };

  const _onTyping = (data: EventUserTyping): void => {
    _changeTyping({
      userId: +data.userId,
      chainId: +data.chainId,
      typing: data.typing,
    });
  };

  const _onUserConnected = (userId: number): void => {
    const index = appStore.onlineUsers.findIndex((u: number) => u === userId);
    if (index < 0) {
      appStore.userConnected(userId);
    }
  };

  const _onUserDisconnected = (userId: number): void => {
    appStore.userDisconnected(userId);
  };

  const _onPushMessage = async (message: MessageEntity): Promise<void> => {
    const currentChainId = chatStore.getId;
    const currentUserId = userStore.getId;
    const model = appStore.getWebSocketModel;

    if (!model) {
      console.error('Pushing message failed - model is null', model);
      return;
    }

    const newMess = message.originalMessage as MessageEntity;
    if (model.userId === newMess.authorId) {
      console.warn('Message from the current user, no need to update anything');
      return;
    }

    newMess.direction = MessageDirectionTypeEnum.Incoming;

    _changeTyping({
      userId: newMess.authorId,
      chainId: newMess.chainId,
      typing: false,
    });

    let existedChain = messengerStore.getChainById(newMess.chainId);
    //NOTE: If chain is not currently in the local storage - trying to get it
    if (!existedChain) {
      await messengerStore.chainById(newMess.chainId);
    }
    //NOTE: Trying to get chain again
    existedChain = messengerStore.getChainById(newMess.chainId);
    if (!existedChain) {
      console.error('Pushing message failed - chain is null', existedChain, newMess.chainId);
      return;
    }
    //NOTE: If message is in archive chain, change chain type to active
    if (existedChain && existedChain.chainType === ChainTypeEnum.Archive) {
      existedChain.chainType = ChainTypeEnum.Active;
    }

    const chainIsMuted = existedChain?.muted;
    const notificationsIsActive = appStore.localNotifications;

    //NOTE: If message is incoming, chain is not current, chain is not muted and notifications are active - playing sound
    const shouldPlaySound =
      newMess.direction === MessageDirectionTypeEnum.Incoming &&
      (newMess.chainId !== currentChainId || newMess.chainId === null) &&
      !chainIsMuted &&
      notificationsIsActive;

    if (shouldPlaySound) {
      if (!isNativeMobile) {
        const audioFile = new Audio(appStore.getAppNotificationSoundPath);
        await audioFile.play();
      } else {
        await useNotifications().scheduleLocalNotification(newMess);
      }
    }

    //TODO: Waiting for API - https://gitlab.united-grid.com/intra/core/-/issues/668
    await chatStore.updateChain(existedChain, false);

    messengerStore.updateLastMessage(toLastModel(newMess), model.userId !== newMess.authorId);

    //NOTE: If the message is in the current chain and the author is not the current user - reading the message
    if (newMess.chainId === currentChainId && newMess.authorId !== currentUserId) {
      chatStore.read(
        {
          authorId: newMess.authorId,
          chainId: newMess.chainId,
          messageId: newMess.id,
          status: MessageDeliveryStatusEnum.ReadAll,
          uniqueId: newMess.uniqueId,
        } as MessagesReadModel,
        false
      );
      await chatStore.markAsRead(newMess.id);
    }

    //NOTE: check if call is in progress when user is added to a group chat
    if (newMess.messageType === MessageTypeEnum.AddParticipant) {
      const activeCalls = await meetStore.getActiveCalls();
      const currentCallSession = activeCalls?.[newMess.chainId];
      const meetRoomId = meetStore.getRoomIdByChainId(newMess.chainId);
      if (currentCallSession && !meetRoomId) {
        const location: RouteLocationRaw = {
          name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
          params: { id: newMess.chainId, type: MessengerChatTypeEnum.Chain },
        };
        const callModalResult = await useMeet().showCallModal(existedChain);
        if (callModalResult === MeetStatusEnum.Accept) {
          await chatStore.getChainById(newMess.chainId);
          meetStore.$patch((state) => {
            state.withVideo = false;
            state.isCallFromPage = true;
          });
          await router.push(location);
        } else if (callModalResult === MeetStatusEnum.Reject) {
          await meetStore.callUser(existedChain);
        }
      }
    }
  };

  const _onEditMessage = (message: MessageEntity): void => {
    const mess = message.originalMessage;
    if (mess !== null) {
      chatStore.redact({
        chainId: mess.chainId,
        message: toLastModel(mess),
        fileInfos: mess.attachedFiles.data,
        uniqueId: mess.uniqueId,
      } as EditMessageModel);

      messengerStore.updateLastMessage(toLastModel(mess), false);
    }
  };

  const _onReadMessage = (message: MessageEntity): void => {
    chatStore.read(
      {
        authorId: message.authorId,
        chainId: message.chainId,
        messageId: message.id,
        status: message.status,
        uniqueId: message.uniqueId,
      } as MessagesReadModel,
      false
    );
  };

  const _onDeleteMessage = (messageId: number, chainId: number): void => {
    const currentChainId = chatStore.chain?.chainId;
    const lastMessage = chatStore.delete(messageId, currentChainId as number);
    const index = messengerStore.data.findIndex((f: MessageChainEntity) => f.chainId === chainId);

    if (~index) {
      if (currentChainId === chainId) {
        messengerStore.updateLastMessage(toLastModel(lastMessage), false);
      } else if (messengerStore.data[index].lastMessage.id === messageId) {
        messengerStore.data[index].lastMessage.text = t('messenger.chatPage.deleted');
        messengerStore.data[index].lastMessage.status = MessageDeliveryStatusEnum.Deleted;
      }
    }
  };

  //TODO: Handle private messages WS event
  const _onSendPrivateMessage = (data: EventPrivateMessage): void => {
    console.log(`Received private message - `, data);
  };

  const _onSendSiteNotification = async (data: EventSiteNotification): Promise<void> => {
    /*
    NOTE: Now model - EventSiteNotification is very limited, so we can't get the full information about the notification.
    Thus, we can only re-fetch the notifications from the server using `$api.notification.getNotifications()` to update notificationsStore.notificationsIds, notificationsStore.data and notificationsStore.totalUnreadCount
    @see src/store/notification.pinia.ts
    @see src/services/notifications.service.ts
    @see Back - https://gitlab.united-grid.com/intra/core/-/issues/846
    @see Front - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1672
    */
    await notificationsStore.notifications();
    // await notificationsStore.unreadNotifications();
  };

  const _onSendUserItem = async (userItemId: number): Promise<void> => {
    /*
      NOTE: Now upon this event, we only get the userItemId, so we can't get the full information about the user item.
      We need to re-fetch the feed using the `feedTypeHelper` method to update postStore.data and postStore.postsIds
      Later, we will need to implement the ability to get the full information about the userItem and just update mentioned store properties with this new userItem
    */
    //NOTE: Commented out as it will be implemented in the future as part of https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1755
    //NOTE: Uncommented as part of https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1755
    await feedTypeHelper(ROUTES_NAME.FEED);
  };

  //TODO: Handle user item been commented WS event
  const _onSendUserItemComment = (userItemId: number, commentId: number): void => {
    console.log(`User item ${userItemId} has a new comment ${commentId}`);
  };

  const _registerConnectionEvents = (): void => {
    if (!connection) {
      console.error('Registration of events failed - connection is null', connection);
      return;
    }

    connection.onclose((data: any) => {
      console.log('>>>Connection closed', data);

      appStore.$patch((state) => {
        state.signalRConnectionStatus = HubConnectionState.Disconnected;
      });
    });

    connection.onreconnected(_onReconnected);

    connection.on('typing', _onTyping);

    connection.on('usersOnline', (data: number[]) => {
      appStore.usersOnline(data);
    });

    connection.on('userConnected', _onUserConnected);

    connection.on('userDisconnected', _onUserDisconnected);

    connection.on('pushMessage', _onPushMessage);

    connection.on('editMessage', _onEditMessage);

    connection.on('readMessage', _onReadMessage);

    connection.on('deleteMessage', _onDeleteMessage);

    connection.on('sendPrivateMessage', _onSendPrivateMessage);

    connection.on('sendSiteNotification', _onSendSiteNotification);

    connection.on('sendUserItem', _onSendUserItem);

    connection.on('sendUserItemComment', _onSendUserItemComment);
  };

  const _retryConnection = async (): Promise<void> => {
    try {
      const toast = await showDismissingToast(t('messenger.errorConnect'), t('retry'), false);

      // User tries to reconnect
      toast.onDidDismiss().then(async (event: OverlayEventDetail) => {
        if (event.role === 'retry') {
          await stopWebSockets();
          await initWebSockets(appStore.getWebSocketModel);
        }
      });
    } catch (err) {
      console.error('Reconnection failed', err);
    }
  };

  const _invokeConnection = async (connection: HubConnection, model: WebSocketModel): Promise<void> => {
    try {
      const invokeModel = {
        coreId: model.coreId,
        companyRowId: model.companyRowId,
        userId: model.userId,
        userRowId: model.userRowId,
      };
      await connection.invoke('connect', invokeModel);

      appStore.$patch((state) => {
        state.isLoading = false;
        state.signalRConnectionId = connection.connectionId!;
        state.signalRConnectionStatus = connection.state;
      });

      meetStore.$patch({
        connectionId: connection.connectionId,
      });
    } catch (e) {
      console.error('Failed to invoke method on the server: ' + e);
      appStore.$patch((state) => {
        state.isLoading = false;
      });
      // If the connection is not established, show a toast with the ability to reconnect
      await _retryConnection();
    }
  };

  const _startConnection = async (connection: HubConnection): Promise<boolean> => {
    try {
      appStore.$patch((state) => {
        state.isLoading = true;
        state.isWaitingForCompleteLogin = false;
      });

      await connection.start();
      console.log(`≥≥≥SignalR connection started. Id: ${connection.connectionId}`);
      return true;
    } catch (err) {
      console.error('SignalR connection error: ' + err);
      appStore.$patch((state) => {
        state.isLoading = false;
      });
      return false;
    }
  };

  const _initSignal = async (model: WebSocketModel): Promise<void> => {
    connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${model.webSocket}/events`)
      .build();

    console.log(`≥≥≥SignalR connection has been initialized: ${connection}`);

    _registerConnectionEvents();

    useMeet().registerEventsVideo(connection);

    await startWebSockets();
  };

  //* Public methods
  async function initWebSockets(model: WebSocketModel | null): Promise<void> {
    if (!model) {
      console.error('Initialization of WebSockets failed - model is null', model);
      return;
    }
    // Stop the current connection if it exists
    if (connection) {
      await connection.stop();
    }

    console.log('≥≥≥Initializing WebSockets', model);

    appStore.$patch((state) => {
      state.signalRConnectionStatus = HubConnectionState.Connecting;
    });

    await _initSignal(model);
  }

  async function startWebSockets(): Promise<boolean> {
    let isConnectSignalR = false;

    try {
      const model = appStore.getWebSocketModel;
      if (!model) {
        console.error('Initialization of WebSockets failed - model is null', model);
        return false;
      }

      //NOTE: If the connection is not initialized, initialize it
      if (!connection) {
        _registerConnectionEvents();
        if (!connection) {
          console.error('Initialization of WebSockets failed - connection is null', connection);
          return false;
        }
      }

      //NOTE: Setting WebSocket as active
      isActive = true;

      appStore.$patch((state) => {
        state.isLoading = false;
      });

      //NOTE: Trying to start the connection
      isConnectSignalR = await _startConnection(connection);

      //NOTE: Trying to connect to the server
      if (isConnectSignalR) {
        await _invokeConnection(connection, model);
      }
      return isConnectSignalR;
    } catch (err) {
      console.error('Starting WebSockets failed', err);
      return false;
    }
  }

  async function stopWebSockets(): Promise<boolean> {
    isActive = false;
    try {
      if (!connection) {
        console.warn('Connection is already undefined, nothing to stop');
        return false;
      }

      console.log(`≥≥≥Trying to stop WebSocket connection: ${connection.connectionId}...`);
      await connection.stop();
      return true;
    } catch (err) {
      console.error('Stopping WebSockets failed', err);
      return false;
    }
  }

  instance = {
    typingEvents,
    initWebSockets,
    startWebSockets,
    stopWebSockets,
  };

  return instance;
};
