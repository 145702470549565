import { StatusBar, Style } from '@capacitor/status-bar';
import { TextZoom } from '@capacitor/text-zoom';
import { isPlatform } from '@ionic/vue';

import type { InterfaceSizeAppEnum } from '@/enums';
import { ThemeAppEnum } from '@/enums';
import { LightOrDark, convertHexToRgb } from '@/helpers';
import { useAppStore } from '@/store';

export type IUseThemeHelper = {
  initTheme: (theme: ThemeAppEnum) => void;
  setTheme: (color: string) => Promise<void>;
  clearTheme: () => Promise<void>;
  initInterface: (font: InterfaceSizeAppEnum) => Promise<void>;
  getAppColor: (color: string | undefined | null) => string;
};

export const useTheme = (): IUseThemeHelper => {
  const _isAndroid = isPlatform('android') && !isPlatform('mobileweb');
  const _isIos = isPlatform('ios') && !isPlatform('mobileweb');
  const _isMobile = _isAndroid || _isIos;

  const initTheme = (theme: ThemeAppEnum): void => {
    const isDarkTheme = useAppStore().isDarkTheme(theme);
    if (isDarkTheme) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  };

  const initInterface = async (font: InterfaceSizeAppEnum): Promise<void> => {
    if (_isMobile) {
      switch (font) {
        case 'small':
          await TextZoom.set({ value: 0.8 });
          break;
        case 'middle':
          await TextZoom.set({ value: 1.0 });
          break;
        case 'large':
          await TextZoom.set({ value: 1.2 });
          break;
      }
    }
  };

  const setTheme = async (color: string): Promise<void> => {
    document.body.style.setProperty('--ion-color-intra', color);
    document.body.style.setProperty('--ion-color-intra-activated', color);
    document.body.style.setProperty('--ion-color-intra-rgb', convertHexToRgb(color));

    const lightOrDark = LightOrDark.getColor(color);

    if (lightOrDark === ThemeAppEnum.Light) {
      document.body.style.setProperty('--ion-color-custom', '#000000');
      if (_isAndroid) {
        await StatusBar.setStyle({
          style: Style.Light,
        });
      }
    } else {
      document.body.style.setProperty('--ion-color-custom', '#ffffff');
      if (_isAndroid) {
        await StatusBar.setStyle({
          style: Style.Dark,
        });
      }
    }

    if (_isAndroid) {
      await StatusBar.setBackgroundColor({
        color: color,
      });
    }
  };

  const clearTheme = async (): Promise<void> => {
    const theme = useAppStore().getLocalTheme;
    initTheme(theme);
    document.body.style.setProperty('--ion-color-intra', '#004d60');
    document.body.style.setProperty('--ion-color-intra-activated', '#16B8B4');
    document.body.style.setProperty('--ion-color-intra-rgb', '0, 77, 96');

    if (_isAndroid) {
      await StatusBar.setBackgroundColor({
        color: '#004d60',
      });
      await StatusBar.setStyle({
        style: Style.Dark,
      });
    }
  };

  const getAppColor = (color: string | undefined | null): string => {
    if (color) {
      return color;
    } else {
      return '#004d60';
    }
  };

  return {
    initTheme,
    setTheme,
    clearTheme,
    initInterface,
    getAppColor,
  };
};
