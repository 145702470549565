import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import { MeetStatusEnum } from '@/enums';
import { $api } from '@/services';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  MeetChainInfo,
  UserViewModel,
  UserMeetModel,
  ResponseCallToUserModel,
  ResponseParticipantsModel,
  UserShortModel,
  MessageChainEntity,
  ResponseAnswerModel,
  ActiveCallsModel,
  ResponseActiveCallsModel,
} from '@/types';

type MeetRoom = {
  callToken: string;
  isRoomActive: boolean;
  chain: MeetChainInfo | null;
  isGroupCall: boolean;
  callUserId: number | null;
  callInProgress: boolean;
  linkedGroupId: number | null;
};

interface MeetState {
  errors: ErrorMessageModel[];
  rooms: Record<string, MeetRoom>;
  currentRoomId: string;
  connectionId: string | null;
  isCallFromPage: boolean;
  withVideo: boolean;
  activeCalls: ActiveCallsModel | null;
  currentGroupId: number | null;
}

export const useMeetStore = defineStore({
  id: 'meet',
  state: (): MeetState => ({
    errors: [],
    rooms: {},
    currentRoomId: '',
    connectionId: '',
    isCallFromPage: false,
    withVideo: false,
    activeCalls: null,
    currentGroupId: null,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getChain:
      (state) =>
      (roomId?: string): MeetChainInfo | null =>
        roomId ? state.rooms?.[roomId]?.chain || null : state.rooms?.[state.currentRoomId]?.chain || null,
    getCurrentRoomId: (state): string => state.currentRoomId,
    callToken:
      (state) =>
      (roomId?: string): string =>
        roomId ? state.rooms?.[roomId]?.callToken : state.rooms?.[state.currentRoomId]?.callToken,
    isGroupCall:
      (state) =>
      (roomId?: string): boolean =>
        roomId ? state.rooms?.[roomId]?.isGroupCall : state.rooms?.[state.currentRoomId]?.isGroupCall,
    isRoomActive:
      (state) =>
      (roomId?: string): boolean =>
        roomId ? state.rooms?.[roomId]?.isRoomActive : state.rooms?.[state.currentRoomId]?.isRoomActive,
    getUser:
      (state) =>
      (userId: number | null, roomId?: string): UserMeetModel | undefined => {
        const currentRoomId = roomId || state.currentRoomId;
        if (state.rooms[currentRoomId]) {
          const users = state.rooms[currentRoomId].chain?.users ?? [];
          const index = users.findIndex((n: UserMeetModel) => n.id === userId);
          return index >= 0 ? users[index] : undefined;
        }
        return undefined;
      },
    getRoomIdByChainId:
      (state) =>
      (chainId: number): string | undefined => {
        for (const roomId of Object.keys(state.rooms)) {
          if (state.rooms[roomId].chain?.chainId === chainId) {
            return roomId;
          }
        }
        return undefined;
      },
    getRoomIdByGroupId:
      (state) =>
      (groupId: number): string | undefined => {
        for (const roomId of Object.keys(state.rooms)) {
          if (state.rooms[roomId].linkedGroupId === groupId) {
            return roomId;
          }
        }
        return undefined;
      },
    getCallInfo:
      (state) =>
      (roomId?: string): { title: string; image: string } | undefined => {
        const currentRoomId = roomId || state.currentRoomId;
        if (!state.rooms?.[currentRoomId]?.chain || !state.rooms?.[currentRoomId]?.callUserId) {
          return undefined;
        }

        if (state.rooms?.[currentRoomId]?.chain?.isGroupChain) {
          return {
            title: state.rooms?.[currentRoomId]?.chain?.title || '',
            image: state.rooms?.[currentRoomId]?.chain?.chainAvatar?.url || '',
          };
        } else {
          const users = state.rooms?.[currentRoomId]?.chain?.users ?? [];
          const index = users.findIndex((n: UserViewModel) => n.id === state.rooms?.[currentRoomId]?.callUserId);
          return index >= 0
            ? {
                title: users[index].fullName,
                image: users[index].image?.url || '',
              }
            : undefined;
        }
      },
  },
  actions: {
    initRoom(roomId: string) {
      this.rooms[roomId] = {
        callToken: '',
        isRoomActive: false,
        chain: null,
        callUserId: null,
        isGroupCall: false,
        callInProgress: false,
        linkedGroupId: null,
      };
    },
    deleteRoom(roomId: string) {
      if (this.rooms[roomId]) {
        const newRooms = Object.keys(this.rooms)
          .filter((key) => key !== roomId)
          .reduce(
            (newRooms, key) => {
              newRooms[key] = this.rooms[key];
              return newRooms;
            },
            {} as { [key: string]: MeetRoom }
          );
        this.rooms = newRooms;
      }
    },
    updateRoom(roomId: string, updates: Partial<MeetRoom>) {
      if (this.rooms[roomId]) {
        this.$patch({
          rooms: {
            [roomId]: { ...this.rooms[roomId], ...updates },
          },
        });
      }
    },
    setRoom(roomId: string, chain: MessageChainEntity | null, callToken: string) {
      if (!chain) {
        return;
      }
      if (this.getCurrentRoomId === '') {
        this.$patch({
          currentRoomId: roomId,
        });
      }
      this.initRoom(roomId);
      this.rooms[roomId] = Object.assign(this.rooms[roomId], {
        isRoomActive: false,
        isGroupCall: chain?.isGroupChain,
        callUserId: chain?.chainUserId,
        callToken: callToken,
      });
      this.setChain(chain, roomId);
    },
    setChain(chain: MessageChainEntity | null, roomId: string) {
      if (!chain) {
        return;
      }
      const { users, ...rest } = chain;
      this.rooms[roomId].chain = {
        users: users.map((user) => {
          const newUser = cloneDeep(user) as unknown as UserMeetModel;
          newUser.isVideoEnabled = false;
          newUser.isVoiceEnabled = false;
          return newUser;
        }),
        ...rest,
      };
    },
    addUser(user: UserShortModel, roomId: string) {
      if (!this.rooms[roomId]?.chain) {
        return;
      }
      const newUser = cloneDeep(user) as unknown as UserMeetModel;
      newUser.isVideoEnabled = false;
      newUser.isVoiceEnabled = false;
      const currentUsers = this.rooms[roomId].chain.users;
      this.rooms[roomId].chain = Object.assign({}, this.rooms[roomId].chain, {
        users: [...currentUsers, newUser],
      });
    },
    async getActiveParticipants(chainId: number): Promise<UserShortModel[]> {
      const response = await $api.meet.getActiveParticipants(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseParticipantsModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
    async getActiveCalls(): Promise<ActiveCallsModel | null> {
      const response = await $api.meet.getActiveCalls();

      if (response.statusCode === 200) {
        const model = response as ResponseActiveCallsModel;
        this.activeCalls = model.data;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return null;
    },
    async callUser(chain: MessageChainEntity | null): Promise<string | undefined> {
      if (!chain) {
        this.$patch({
          isCallFromPage: false,
        });
        return undefined;
      }

      const response = await $api.meet.callUser({
        chainId: chain.chainId,
      });

      if (response.statusCode === 200) {
        const model = response as ResponseCallToUserModel;
        // TODO
        // if (model.data.status === CallStartStatusEnum.Connect) {
        //   await this.reject(chainId);
        //   return undefined;
        // }
        this.setRoom(model.data.roomName, chain, model.data.participantJwtToken);
        return model.data.roomName;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async answerUser(chainId: number | null, roomId: string, result: MeetStatusEnum): Promise<boolean | undefined> {
      if (!chainId) {
        return;
      }
      try {
        const response = await $api.meet.answerUser({
          chainId,
          result,
        });

        if (response.statusCode === 200) {
          const model = response as ResponseAnswerModel;
          this.rooms[roomId].callToken = model.data.participantJwtToken;
          return result === MeetStatusEnum.Accept;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      } catch (e) {
        return undefined;
      }
    },
    async reject(chainId: number | null): Promise<void> {
      if (!chainId) {
        return;
      }
      const response = await $api.meet.reject(chainId);

      if (response.statusCode === 200) {
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    userVoiceMute(userId: number, isEnabled: boolean, roomId?: string) {
      const incomingRoomId = roomId || this.currentRoomId;
      const users = this.rooms[incomingRoomId].chain?.users ?? [];
      const index = users.findIndex((n: UserMeetModel) => n.id === userId);

      if (index >= 0) {
        users[index].isVoiceEnabled = isEnabled;
      }
    },
    userVideoMute(userId: number, isEnabled: boolean, roomId?: string) {
      const incomingRoomId = roomId || this.currentRoomId;
      const users = this.rooms[incomingRoomId].chain?.users ?? [];
      const index = users.findIndex((n: UserMeetModel) => n.id === userId);

      if (index >= 0) {
        users[index].isVideoEnabled = isEnabled;
      }
    },
    addError(key: string, error: string) {
      const index = this.errors.findIndex((n) => n.key === key);
      if (index >= 0) {
        this.errors[index].errors = [error];
      } else {
        this.errors.push({
          key: key,
          errors: [error],
        });
      }
    },
    clearErrors() {
      this.errors = [];
    },
  },
  persist: true,
});
