// import * as SentryBrowser from '@sentry/browser';
import * as Sentry from '@sentry/capacitor';
import { BrowserTracing } from '@sentry/tracing';
import * as SentrySibling from '@sentry/vue';
import type { App } from 'vue';

import packageJson from '../../package.json';
import router from '../router';

export const initSentry = (app: App<Element>): void => {
  console.log('Sentry init is', import.meta.env.MODE);

  return Sentry.init(
    {
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: `intra-ionic@${packageJson.version}${import.meta.env.VITE_APP_STAGE}`,
      dist: `${packageJson.build}`,
      environment: import.meta.env.DEV ? 'dev' : 'production',
      tracesSampleRate: 1.0,
      logErrors: false,
      trackComponents: true,
      //TODO Sentry Replay:
      // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
      // replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
      // replaysOnErrorSampleRate: 1.0,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: SentrySibling.vueRouterInstrumentation(router),
        }),
        // SentryBrowser.replayIntegration({
        //   // Additional SDK configuration goes in here, for example:
        //   maskAllText: true,
        //   blockAllMedia: true,
        // }),
      ],
      beforeSend(event, hint) {
        console.log('Sentry event', event, hint);
        return event;
      },
    },
    SentrySibling.init
  );
};
