import { sha512 } from 'js-sha512';

import { uint8ToBase64 } from '@/helpers';
import axios from '@/services/axios';
import type {
  ResponseAuthCoreTokenModel,
  RequestTokenUserModel,
  RequestAuthNetworkUser,
  RequestAuthHomeUser,
  ResponseAuthUserTokenModel,
  ResponseNetworkLinkModel,
  ResponseErrorModel,
  ResponseNetworksModel,
} from '@/types';

export class AuthApiService {
  /**
   * Hashes the provided clientSecret and returns the hashed value.
   * @param clientSecret - The client secret to be hashed.
   * @returns The hashed clientSecret.
   */
  private hashClientSecret(clientSecret: string): string {
    return uint8ToBase64(sha512.array(clientSecret));
  }

  async oauth(clientSecret: string, withHash: boolean): Promise<ResponseNetworkLinkModel | ResponseErrorModel> {
    const secret = withHash ? this.hashClientSecret(clientSecret) : clientSecret;
    return axios.get(`/oauth/link?clientSecret=${secret}&type=ApiCore`);
  }
  async networks(params: RequestAuthNetworkUser): Promise<ResponseNetworksModel | ResponseErrorModel> {
    if (params.clientSecret) {
      params.clientSecret = this.hashClientSecret(params.clientSecret);
    }
    return axios.post('/oauth/networks', params);
  }
  async homeCode(params: RequestAuthHomeUser): Promise<ResponseAuthCoreTokenModel | ResponseErrorModel> {
    return axios.post('/oauth/homeCode', params);
  }
  async token(params: RequestTokenUserModel): Promise<ResponseAuthUserTokenModel | ResponseErrorModel> {
    return axios.post('/oauth/token', params);
  }
}
