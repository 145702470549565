<template>
  <div class="user">
    <div class="avatar" @click.stop="openProfile(author.id)">
      <user-avatar slot="start" :fio="author.fullName" :url="author.image?.url ?? ''" :image="author.image" />
    </div>
    <ion-label>
      <span class="info">
        <span
          class="author"
          :class="{
            'with-margin': !group?.title || author?.badges,
          }"
          @click.stop="openProfile(author.id)"
        >
          {{ author.fullName }}
        </span>
        <span v-if="author?.badges" class="badges">
          <badges-wrapper :badges="author.badges.map((badge) => badge.id)" :type="BadgeDisplayType.UserName" />
        </span>
        <ion-icon v-if="group?.title" :icon="icons.caret" />
        <span v-if="group?.title" class="group" @click.stop="openGroup(group?.id)">
          {{ group.title }}
        </span>

        <ion-icon v-if="isPinned" class="pinned" :icon="icons.pin" />
      </span>
      <p v-if="initiator && initiator.id !== author.id" class="published" @click.stop="openProfile(initiator.id)">
        {{ t('feed.publishedOnBehalf') }}
        <a class="initiator">
          {{ initiator.fullName }}
        </a>
      </p>
      <p v-if="plannedDate" class="date">
        {{ t('planned') + ': ' + formatDateHelper(plannedDate, 'long') }}
      </p>
      <p v-else-if="createdAt" class="date">
        {{ formatDateHelper(createdAt, 'long') }}
      </p>
    </ion-label>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonIcon } from '@ionic/vue';
import { bookmarkOutline, eyeOffOutline, closeCircleOutline, caretForward, pinOutline } from 'ionicons/icons';
import type { PropType } from 'vue';
import 'swiper/css';
import { useRouter } from 'vue-router';

import { UserAvatar, BadgesWrapper } from '@/components';
import { BadgeDisplayType } from '@/enums';
import type { FeedTypeEnum } from '@/enums';
import { formatDateHelper } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import type { UserShortModel, UserGroupShortModel } from '@/types';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const icons = {
  caret: caretForward,
  bookmark: bookmarkOutline,
  eyeOff: eyeOffOutline,
  closeCircle: closeCircleOutline,
  pin: pinOutline,
};

defineProps({
  group: {
    type: Object as PropType<UserGroupShortModel | null>,
    default: null,
  },
  author: {
    type: Object as PropType<UserShortModel | UserShortModel>,
    required: true,
  },
  initiator: {
    type: Object as PropType<UserShortModel | UserShortModel | null>,
    required: true,
  },
  createdAt: {
    type: String,
    default: '',
  },
  plannedDate: {
    type: String || undefined,
    default: undefined,
  },
  messageType: {
    type: String as PropType<FeedTypeEnum | null>,
    default: null,
  },
  isPinned: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const router = useRouter();
const openGroup = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.GROUP_BY_ID,
    params: { id: id },
  });
};
const openProfile = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.USER_BY_ID,
    params: { id: id },
  });
};

/* Deprecated  */
/* const status: ComputedRef<string> = computed(() => {
  if (props.messageType === FeedTypeEnum.Announcement)
    return `(${t('feed.announcement.title')})`;
  return '';
}); */
</script>

<style scoped lang="scss">
.user {
  display: inline-flex;
  align-items: center;
  padding-right: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  .avatar {
    flex-shrink: 0;

    ion-avatar {
      width: 40px;
      height: 40px;
    }
  }

  ion-label {
    margin-left: 1rem;
    word-break: break-word;
  }

  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .author {
      font-weight: 500;
    }
    .initiator {
      text-decoration: underline;
    }
    .author,
    .initiator {
      font-size: 0.9rem;
      &:hover {
        cursor: pointer;
        color: var(--ion-color-medium);
      }
      &.with-margin {
        margin-right: app-padding(sm);
      }
    }

    .badges {
      display: inline-flex;
      align-items: center;
      justify-content: start;
    }

    .status {
      font-size: 0.8rem;
      color: var(--ion-color-medium);
      margin-right: 0.5rem;
    }

    .date {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .group {
      display: block;
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      &:hover {
        cursor: pointer;
        color: var(--ion-color-medium);
      }
    }

    ion-icon {
      color: var(--ion-color-medium);
    }
  }
}

.sc-ion-label-md-s {
  & p.date,
  p.published {
    font-size: 0.9rem;
  }

  & p.published {
    margin-bottom: 0;
  }
}

@include respond-to-min-width(2xl) {
  .user {
    .info {
      .author {
        font-size: 1rem;
      }
    }
  }
  .sc-ion-label-md-s p.date {
    font-size: 1rem;
  }
}
</style>
