<template>
  <div class="documents">
    <div v-for="item in combineAttachments" :key="item.data.id" class="document-item">
      <docs-item
        :doc="item"
        :view-type="DocsViewTypeEnum.Item"
        :file-menu-is-disabled="true"
        @onWikiOpen="wikiOpen"
        @onWikiPreview="wikiPreview"
        @onImageView="imageView"
      />
      <!-- @onVideoView="videoView" -->
      <ion-button
        v-if="withControls"
        class="document-delete-btn"
        color="dark"
        fill="clear"
        @click="removeConfirm(item.data.id)"
      >
        <ion-icon slot="icon-only" :icon="icons.delete" color="danger" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IonButton,
  IonIcon,
  // modalController,
  alertController,
} from '@ionic/vue';
import { closeOutline, pencilOutline, arrowBackOutline, trashOutline, checkmarkOutline } from 'ionicons/icons';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { DocsItem } from '@/components';
import { DocsViewTypeEnum, DocumentTypeEnum } from '@/enums';
import {
  componentImagesViewer,
  // filesHybrid
} from '@/helpers';
import { useI18n } from '@/i18n';
// import { VideoViewerModal } from '@/modals';
import { ROUTES_NAME } from '@/router';
import { useProjectsStore } from '@/store';
import type { TaskManagementTaskModel, DocEntity, FileModel } from '@/types';

const props = defineProps({
  taskId: {
    type: Number,
    required: true,
  },
  withControls: {
    type: Boolean,
    required: true,
  },
});
const icons = {
  close: closeOutline,
  pencil: pencilOutline,
  arrow: arrowBackOutline,
  delete: trashOutline,
  checkmark: checkmarkOutline,
};

const { t } = useI18n();
const projectsStore = useProjectsStore();

const taskData: ComputedRef<TaskManagementTaskModel | undefined> = computed(() =>
  projectsStore.getTaskById(props.taskId)
);

const combineAttachments: ComputedRef<DocEntity[]> = computed(() => {
  if (taskData.value) {
    return [
      ...taskData.value.files.data.map((file) => ({
        documentType: DocumentTypeEnum.File,
        data: file,
      })),
      ...taskData.value.wikis.data.map((wiki) => ({
        documentType: DocumentTypeEnum.Wiki,
        data: wiki,
      })),
    ];
  } else {
    return [];
  }
});

const removeConfirm = async (fileId: number) => {
  const alert = await alertController.create({
    message: t('files.deleteConfirm'),
    buttons: [
      {
        text: t('no'),
        role: 'cancel',
        cssClass: 'custom-alert_buttons',
      },
      {
        text: t('yes'),
        cssClass: 'custom-alert_buttons',
        handler: async () => {
          await removeFile(fileId);
        },
      },
    ],
  });

  await alert.present();
};

const removeFile = async (fileId: number) => {
  await projectsStore.taskRemoveFiles(props.taskId, [fileId]);
};

const router = useRouter();
const wikiOpen = async (wikiId: number) => {
  await router.push({
    name: ROUTES_NAME.WIKI_BY_ID,
    params: { id: wikiId },
  });
};

const wikiPreview = async (wikiId: number) => {
  await router.push({
    name: ROUTES_NAME.WIKI_BY_ID,
    params: { id: wikiId },
  });
};

const imageView = async (file: FileModel) => {
  await componentImagesViewer(0, [{ file, text: '' }]);
};

/* NOTE: Not in use
const videoView = async (file: FileModel) => {
  const modal = await modalController.create({
    component: VideoViewerModal,
    componentProps: {
      video: file,
      filesHybrid: filesHybrid,
    },
    cssClass: 'fullscreen',
  });
  await modal.present();
};
*/
</script>
<style scoped lang="scss">
.documents {
  max-height: 300px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  .document-item {
    display: flex;
    .document-delete-btn {
      position: relative;
      margin: 0;
      --border-radius: 0;
      height: unset;
      min-width: 54px;
      ion-icon {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
