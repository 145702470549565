<template>
  <div>
    <textarea ref="editorRef" />
  </div>
</template>

<script setup lang="ts">
// import { alertController } from '@ionic/vue';
import type { Editor, EditorEvent } from 'tinymce/tinymce';
import tinymce from 'tinymce/tinymce';
import type { PropType } from 'vue';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import 'tinymce/models/dom';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
// Plugins
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/save';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/preview';
/**
 * Potential plugins to remove
 */
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/directionality';
/**
 * Absent plugins
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/help';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/autosave';
 */
import 'tinymce/icons/default/icons';
import { useRouter } from 'vue-router';

import { UploadFileTypes } from '@/enums';
import {
  componentDocsAttachment,
  componentDocsUploadFile,
  componentRichTextEditorFullscreenModal,
  filesHybrid,
  isNativeMobile,
  useSearchAutocomplete,
  isBlob,
  useRichTextEditor,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useDocStore, useGroupsStore, useWikiStore } from '@/store';
import type { _UserModel, DocModelWithType, FileModel, GroupEntity, TopicEntity } from '@/types';

// Props
const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  height: {
    type: Number,
    default: 500,
  },
  groupId: {
    type: null as unknown as PropType<number | null>,
    default: null,
  },
  isSimpleMode: {
    type: Boolean,
    default: false,
  },
});

// Types
interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

// Store
const wikiStore = useWikiStore();

// Helpers
const richTextEditorHelper = useRichTextEditor();
const plugins = richTextEditorHelper.getPlugins(props.isSimpleMode);
const toolbar = richTextEditorHelper.getToolbar(props.isSimpleMode);

// Router
const router = useRouter();

// Store
const docStore = useDocStore();

// Refs
const editorRef = ref<HTMLElement | null>(null);
const editorInstance = ref<Editor | null>(null);
const isInternalUpdate = ref(false);
const currentPickerCallback = ref<any>(null);

// Actions
const uploadFromDevice = async (isImage: boolean) => {
  const dialogBlock = document.querySelector('.tox-tinymce-aux') as HTMLElement;
  if (dialogBlock) {
    dialogBlock.style.visibility = 'hidden';
  }

  const files = await componentDocsUploadFile(
    isImage ? UploadFileTypes.SingleImage : UploadFileTypes.SingleVideo,
    undefined,
    undefined,
    undefined,
    props.groupId ? useGroupsStore().getGroupById(props.groupId) : undefined
  );

  if (files?.length) {
    try {
      const file = files[0].data as FileModel;
      if (file) {
        await setMediaOnTinyMce(file);
      }
    } catch (error) {
      console.error('[TinyMCE] An error occurred while processing a file:', error);
    }
  }

  if (dialogBlock) {
    dialogBlock.style.visibility = 'visible';
  }
};

const selectFromUploadedFiles = async () => {
  const dialogBlock = document.querySelector('.tox-tinymce-aux') as HTMLElement;
  if (dialogBlock) {
    dialogBlock.style.visibility = 'hidden';
  }

  const result = await componentDocsAttachment(null);

  const file = result.data?.[0] as DocModelWithType<FileModel>;

  if (file?.data?.mimeType.startsWith('image/') || file?.data?.mimeType.startsWith('video/')) {
    try {
      await setMediaOnTinyMce(file.data);
    } catch (error) {
      console.error('[TinyMCE] An error occurred while processing a file:', error);
    }
  }

  if (dialogBlock) {
    return (dialogBlock.style.visibility = 'visible');
  }
};

const setMediaOnTinyMce = async (file: FileModel | undefined) => {
  if (file) {
    isInternalUpdate.value = true;

    currentPickerCallback.value(file.apiUrl, {
      alt: file.name,
    });
  }
};

type ProgressFn = (percent: number) => void;
const imagesUploadHandler = async (blobInfo: BlobInfo, progress: ProgressFn): Promise<string> => {
  const imageBlob = blobInfo.blob();

  if (!isBlob(imageBlob)) {
    console.error('The uploaded file is not a Blob');
    return '';
  }

  // Displaying the percentage is hidden by the styles .tox-progress-bar.tox-progress-indicator
  if (progress) {
    progress(0);
  }

  const uploadFile = await filesHybrid.uploadFile({
    blob: imageBlob,
    name: blobInfo.filename(),
    size: imageBlob.size,
    mimeType: imageBlob.type,
  });

  if (uploadFile) {
    await docStore.createFiles([uploadFile], null, props.groupId);
    return 'ok';
  } else {
    return '';
  }
};

/**
 // Arrow function to delay execution by a given number of milliseconds
const delay = (ms: number): Promise<void> =>
new Promise((resolve) => setTimeout(resolve, ms));

 // Arrow function to check if the topmost alert has a specific ID
const isSpecificAlertActive = async (alertId: string): Promise<boolean> => {
  const top = await alertController.getTop();
  return !!(top && top.id === alertId); // Check if the top alert matches the specified ID
};

// Function to log directionality details
const logTextDirectionality = (editor: Editor): void => {
  const directionality = editor.getBody().dir || 'ltr'; // Direction in <body>
  const computedDirection = window.getComputedStyle(editor.getBody()).direction; // Computed direction from CSS

  console.log(`Body directionality attribute: ${directionality}`);
  console.log(`Computed directionality from CSS: ${computedDirection}`);
};

// Function to force the directionality to left-to-right after insertion
const resetTextDirectionality = (editor: Editor): void => {
  editor.getBody().dir = 'ltr'; // Force left-to-right
};

// Main arrow function to show alerts with delays and checks
const mockGermanCharacters = async (editor: Editor): Promise<void> => {
  const specialCharacters = [
    { name: 'ä', description: 'a with umlaut' },
    { name: 'ö', description: 'o with umlaut' },
    { name: 'ü', description: 'u with umlaut' },
    { name: 'Ä', description: 'A with umlaut' },
    { name: 'Ö', description: 'O with umlaut' },
    { name: 'Ü', description: 'U with umlaut' },
    { name: 'ß', description: 'sharp S' },
  ];

  const placeholderText = 'Lorem ipsum';

  for (const char of specialCharacters) {
    // Check if a specific alert is active before showing the next one
    while (await isSpecificAlertActive('MockGermanCharactersAlert')) {
      console.log('Waiting for the current alert to close...');
      await delay(1000); // Wait 1 second before checking again
    }

    // Create and show an alert for the current special character
    const alert = await alertController.create({
      header: 'Insert Special Character',
      message: `We are about to insert ${char.description}. Do you want to proceed?`,
      id: 'MockGermanCharactersAlert', // Assign a unique ID to this alert
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Operation cancelled');
          },
        },
        {
          text: 'OK',
          handler: async () => {
            // Clear the editor content and insert the special character
            editor.setContent(''); // Clear previous content
            editor.insertContent(`<p>${placeholderText} ${char.name}</p>`);
            console.log(`Inserted ${char.name}`);

            // Log the current directionality after insertion
            logTextDirectionality(editor);

            // Reset directionality to left-to-right
            resetTextDirectionality(editor);
          },
        },
      ],
    });

    // Present the alert and wait for user interaction
    await alert.present();
    // Delay for 5 seconds before showing the next alert
    await delay(5000);
  }
};

// Function to confirm simulation via Ionic Alert
const confirmSimulation = async (editor: Editor): Promise<void> => {
  const existingAlert = await alertController.getTop();
  if (existingAlert?.id === 'ConfirmSimulationAlert') {
    return; // Do nothing if the alert is already active
  }

  const alert = await alertController.create({
    header: 'Confirm Simulation',
    message: 'Are you sure you want to simulate German characters?',
    id: 'ConfirmSimulationAlert', // Assign a unique ID to this alert
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          console.log('Simulation canceled');
        },
      },
      {
        text: 'OK',
        handler: () => {
          console.log('Simulation started');
          mockGermanCharacters(editor);
        },
      },
    ],
  });

  await alert.present();
};
//! DEBUG - Mocking German words
*/

// const LTR_UNICODE = '\u202D'; // Left-to-Right Override (U+202D)

/**
 * Function to ensure that the Left-to-Right Unicode character is present at the start of the content
const ensureLTRDirectionality = (editor: Editor) => {
  const content = editor.getContent({ format: 'raw' });

  // Check if the content starts with the LTR Unicode, if not, prepend it
  if (!content.startsWith(LTR_UNICODE)) {
    const newContent = content + LTR_UNICODE;
    editor.setContent(newContent, { format: 'raw' });
  }
};
*/

const tinyMceSetupFunction = (editor: Editor): void => {
  // Save editor instance to reactive variable
  editorInstance.value = editor;

  // Set initial content on editor init
  editor.on('init', () => {
    //TODO: Make sanitization og props.value here
    editorInstance.value?.setContent(props.value);
  });

  // Handle content input changes
  const handleContentChange = () => {
    // ensureLTRDirectionality(editor); // Ensure LTR on every content change
    const content = editorInstance.value?.getContent();
    isInternalUpdate.value = true;
    emit('update:value', content);
  };

  const onClick = (event: EditorEvent<MouseEvent>) => {
    const target = event.target as HTMLElement;
    const pattern = /^[ugt]:\d+$/;
    const href = target.getAttribute('href');

    if (!href) {
      console.error('No href attribute');
      return;
    }

    if (target.tagName === 'A' && pattern.test(href)) {
      event.preventDefault();
      const [identifier, id] = href.split(':');

      const routerNameMapper = {
        u: ROUTES_NAME.USER_BY_ID,
        g: ROUTES_NAME.GROUP_BY_ID,
        t: ROUTES_NAME.TOPIC_BY_ID,
      };
      const routerLink = router.resolve({
        name: routerNameMapper[identifier as 'g' | 't' | 'u'],
        params: { id },
      });

      if (isNativeMobile) {
        router.push(routerLink);
      } else {
        window.open(routerLink.href, '_blank');
      }
    }
  };

  const onOpenWindow = () => {
    const dialogTitleBlock = document.querySelector('.tox-dialog__title');
    const dialogBrowseUrls = document.querySelectorAll(
      '.tox-dialog .tox-browse-url'
      // eslint-disable-next-line no-undef
    ) as NodeListOf<HTMLElement>;

    if (
      dialogTitleBlock?.textContent === 'Insert/Edit Image' ||
      dialogTitleBlock?.textContent === 'Insert/Edit Media'
    ) {
      for (const dialogBrowseUrl of dialogBrowseUrls) {
        dialogBrowseUrl.style.display = 'none';
      }

      // Add "Select file" and "Upload file" links
      const addFileLinks = () => {
        const wrapper = document.createElement('div');
        wrapper.style.cssText =
          'height: 20px; margin: 10px 0; width: 100%; display: flex; justify-content: space-between;';
        const formGroupFirst = document.querySelector('.tox-form__group');
        if (formGroupFirst) {
          formGroupFirst.appendChild(wrapper);
        }

        const createLink = (text: string, callback: () => void) => {
          const link = document.createElement('a');
          link.textContent = text;
          link.style.cssText = 'float: left;';

          link.addEventListener('click', async () => {
            callback();
          });

          return link;
        };

        wrapper.appendChild(createLink('Select file', selectFromUploadedFiles));

        const uploadFile = (isImage: boolean) => {
          return async () => {
            await uploadFromDevice(isImage);
          };
        };

        wrapper.appendChild(
          createLink('Upload file', uploadFile(dialogTitleBlock?.textContent === 'Insert/Edit Image'))
        );

        const uploadElements = document.querySelectorAll('.js-wiki-edit-upload');
        uploadElements.forEach((uploadElement) => {
          const clonedUploadElement = uploadElement.cloneNode(true) as HTMLElement;
          clonedUploadElement.classList.remove('g-hidden');
          wrapper.appendChild(clonedUploadElement);
        });
      };

      // Add custom elements
      addFileLinks();

      const dialogBodyNav = document.querySelector('.tox-dialog__body-nav') as HTMLElement;
      if (dialogBodyNav) {
        dialogBodyNav.style.display = 'none';
      }

      for (const dialogBrowseUrl of dialogBrowseUrls) {
        dialogBrowseUrl.click();
      }
    }
  };

  // Set listeners for input and change events
  editor.on('input', handleContentChange);
  editor.on('change', handleContentChange);

  // Handle click on links
  editor.on('click', onClick);

  // Customize dialog for inserting/editing images and media
  editor.on('OpenWindow', onOpenWindow);

  // Add custom Fullscreen button
  editor.ui.registry.addButton('customFullscreenButton', {
    icon: 'fullscreen',
    onAction: async () => {
      if (editor.queryCommandState('ToggleToolbarDrawer')) {
        editor.execCommand('ToggleToolbarDrawer');
      }
      const result = await componentRichTextEditorFullscreenModal(props.value, props.groupId);
      if (result.data) {
        editorInstance.value?.setContent(result.data);
      }
    },
  });

  // Add autocompleter for user mentions
  editor.ui.registry.addAutocompleter('user-mention', {
    trigger: '@',
    minChars: 3,
    columns: 1,
    onAction: (autocompleteApi: any, rng: Range, value: string, meta: Record<string, string>) => {
      if (meta.type === 'divider') {
        return;
      }

      const parts = value.split('|');
      const name = parts[0];
      const id = parts[1];
      const hrefUrl = meta.type === 'users' ? `u:${id}` : `g:${id}`;
      const link = `<a href="${hrefUrl}">@${name}</a>`;

      if (meta.type === 'users') {
        wikiStore.setMentionedUserId([Number(id)]);
      }

      editor.selection.setRng(rng);
      editor.insertContent(link);
      autocompleteApi.hide();
    },
    fetch: async (pattern: string): Promise<any> => {
      const userAutoComplete = useSearchAutocomplete('user');
      const groupAutoComplete = useSearchAutocomplete('group');

      const responses = await Promise.all([
        userAutoComplete.autocomplete(pattern),
        groupAutoComplete.autocomplete(pattern),
      ]);

      const users: _UserModel[] | null = responses[0];
      const groups: GroupEntity[] | null = responses[1];

      const results = [];
      const { t } = useI18n();

      if (users?.length) {
        results.push({
          type: 'divider',
          value: '',
          classes: 'header',
          text: t('users.title'),
          meta: {
            type: 'divider',
          },
        });
        results.push(
          ...users.map((user) => ({
            type: 'cardtext',
            value: `${user.fullName}|${user.id}`,
            text: user.fullName,
            meta: {
              type: 'users',
            },
          }))
        );
      }

      if (groups?.length) {
        results.push({
          type: 'divider',
          value: '',
          text: t('appMenu.groups'),
          classes: 'header',
          meta: {
            type: 'divider',
          },
        });
        results.push(
          ...groups.map((group) => ({
            type: 'cardtext',
            value: `${group.mainAlias}|${group.id}`,
            text: group.mainAlias,
            meta: {
              type: 'groups',
            },
          }))
        );
      }

      return results;
    },
  });

  // Add autocompleter for tag mentions
  editor.ui.registry.addAutocompleter('tag-mention', {
    trigger: '#',
    minChars: 3,
    columns: 1,
    onAction: (autocompleteApi: any, rng: Range, value: string) => {
      const parts = value.split('|');
      const title = parts[0];
      const hrefUrl = `t:${parts[1]}`;
      const link = `<a href="${hrefUrl}">#${title}</a>`;

      editor.selection.setRng(rng);
      editor.insertContent(link);
      autocompleteApi.hide();
    },
    fetch: async (pattern: string): Promise<any> => {
      const topicAutocomplete = useSearchAutocomplete('topic');

      const topics: TopicEntity[] | null = await topicAutocomplete.autocomplete(pattern);
      return topics?.map((topic) => ({
        type: 'cardtext',
        value: `${topic.title}|${topic.id}`,
        text: topic.title,
      }));
    },
  });
};

// TinyMCE init
const tinyMceInit = () => {
  tinymce.init({
    target: editorRef.value as HTMLElement,
    height: props.height,
    menubar: false,
    statusbar: false,
    relative_urls: false,
    remove_script_host: true,
    skin: false,
    content_css: false,
    plugins: plugins,
    content_style: `
      body { font-family: Roboto, sans-serif; }
      h1,h2,h3,h4,h5,h6 { font-family: Roboto, sans-serif; }
    `,
    toolbar: toolbar,
    paste_data_images: true,
    // Functions
    images_upload_handler: imagesUploadHandler,
    setup: tinyMceSetupFunction,
    file_picker_callback: (callback) => {
      currentPickerCallback.value = callback;
    },
    //NOTE: Links settings
    default_link_target: '_blank',
    link_default_protocol: 'https',
    link_assume_external_targets: true,
    link_context_toolbar: true,
    target_list: false,
    link_title: false,
    directionality: 'ltr',
    // xss_sanitization: true,
  });
};

// Watchers
watch(
  () => props.value,
  () => {
    if (editorInstance.value && (wikiStore.isDraftDeleted || !isInternalUpdate.value)) {
      editorInstance.value.setContent(props.value);
    }

    isInternalUpdate.value = false;
  }
);

// Lifecycle
onMounted(() => {
  console.log('≥≥≥Creating TinyMCE instance'); //! DEBUG
  tinyMceInit();
  console.log('≥≥≥TinyMCE instance created - ', editorInstance.value); //! DEBUG
});

onBeforeUnmount(() => {
  console.log('≥≥≥Destroying TinyMCE instance'); //! DEBUG
  if (editorInstance.value) {
    editorInstance.value.destroy();
  }
});

// Emits
const emit = defineEmits(['update:value']);
</script>

<style scoped>
.no-top-borders {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
