// import type { OpenAI } from 'openai';
import type { OpenAI } from 'openai';
import type { Thread, Assistant } from 'openai/src/resources/beta';
import { defineStore } from 'pinia';

import { AiModeEnum } from '@/enums';
import { useAiAssistant } from '@/helpers';
import { useAppStore } from '@/store';
import type { ChatGPTMessage } from '@/types';

interface AiAssistantState {
  assistant: Assistant | null;
  isTyping: boolean;
  sendFailed: boolean;
  currentMode: AiModeEnum;
  currentThreadId: string;
  threads: {
    id: string;
    obj: Thread | null;
    files: OpenAI.Beta.Threads.Message.Attachment[];
    images: OpenAI.Images.Image[];
    messages: ChatGPTMessage[];
  }[];
  formText: string;
  showDeleteMessageAlert: boolean;
}

export const useAiAssistantStore = defineStore({
  id: 'aiAssistant',
  state: (): AiAssistantState => ({
    assistant: null,
    isTyping: false,
    sendFailed: false,
    currentMode: AiModeEnum.Default,
    currentThreadId: '',
    threads: [],
    formText: '',
    showDeleteMessageAlert: true,
  }),
  getters: {
    getAssistant: (state) => state.assistant,
    getMode: (state) => state.currentMode,
    getThread: (state) => state.threads.find((t) => t.id === state.currentThreadId),
    getThreadFiles: (state) => state.threads.find((t) => t.id === state.currentThreadId)?.files,
    getThreadImages: (state) => state.threads.find((t) => t.id === state.currentThreadId)?.images,
    getThreadMessages: (state) => state.threads.find((t) => t.id === state.currentThreadId)?.messages,
    getMessagesById: (state) => (threadId: string) => state.threads.find((t) => t.id === threadId)?.messages,
    getMessageById: (state) => (id: string) =>
      state.threads.find((t) => t.id === state.currentThreadId)?.messages.find((m) => m.id === id),
  },
  actions: {
    setAssistant(assistant: Assistant) {
      this.assistant = assistant;
    },
    setTyping(isTyping: boolean) {
      this.isTyping = isTyping;
    },
    setMode(mode: AiModeEnum) {
      this.currentMode = mode;
    },
    setThread(thread: Thread) {
      this.threads.push({
        id: thread.id,
        obj: thread,
        files: [],
        images: [],
        messages: [],
      });
      this.currentThreadId = thread.id;
    },
    setFormText(text: string) {
      this.formText = text;
    },
    setShowDeleteMessageConfirmation(show: boolean) {
      this.showDeleteMessageAlert = show;
    },
    async deleteMessage(threadId: string, id: string): Promise<boolean> {
      // const remoteResult = await useAiAssistant().deleteMessage(threadId, id);
      // if (!remoteResult) {
      //   console.error('Failed to delete message');
      //   return false;
      // }

      const messages = this.getMessagesById(threadId);
      if (!messages?.length) {
        this.logError('Messages are empty');
        return false;
      }
      const index = messages.findIndex((m) => m.id === id);
      if (~index) {
        messages.splice(index, 1);
        return true;
      }
      return false;
    },
    pushMessage(threadId: string, message: ChatGPTMessage): boolean {
      return this.pushItem(threadId, message, 'messages');
    },
    pushFile(threadId: string, file: OpenAI.Beta.Threads.Message.Attachment): boolean {
      return this.pushItem(threadId, file, 'files');
    },
    pushImage(threadId: string, image: { id?: string; url?: string }): boolean {
      return this.pushItem(threadId, image, 'images');
    },
    pushItem<T>(threadId: string, item: T, key: 'files' | 'images' | 'messages'): boolean {
      if (!item) {
        this.logError(`${key} is empty`);
        return false;
      }
      const thread = this.threads.find((t) => t.id === threadId);
      if (thread) {
        (thread[key] as T[]).push(item);
        return true;
      }
      return false;
    },
    updateMessage(id: string, propObj: Partial<ChatGPTMessage>): boolean {
      const message = this.getMessageById(id);
      if (!message) {
        this.logError('Message not found');
        return false;
      }
      Object.assign(message, propObj);
      return true;
    },
    clearMessages(threadId: string): void {
      const thread = this.threads.find((t) => t.id === threadId);
      if (thread) thread.messages.splice(0);
    },
    deleteThread(threadId: string): boolean {
      this.clearMessages(threadId);
      const index = this.threads.findIndex((t) => t.id === threadId);
      if (~index) {
        this.threads.splice(index, 1);
        return true;
      }
      return false;
    },
    async sendMessage(sendObj: {
      threadId: string;
      text?: string;
      files?: OpenAI.FileObject[];
      id?: string;
    }): Promise<boolean> {
      const { threadId, text, files, id } = sendObj;

      if (!this.assistant) {
        this.logError('No assistant exists');
        return false;
      }

      if (!text?.trim() && !id) {
        this.logError('Text or id is required');
        return false;
      }

      this.setFormText('');

      const appStore = useAppStore();
      const aiAssistantHelper = useAiAssistant();

      let message: ChatGPTMessage | undefined;
      if (id) {
        message = this.getMessageById(id);
      } else {
        if (!text?.trim().length) {
          console.error('Failed to send message: Text is empty');
          return false;
        }

        message = {
          id: Date.now().toString(),
          role: 'user',
          content: text,
          attachments: { files: [], images: [] },
          created: Date.now(),
          language: appStore.locale,
          isProcessing: false,
        };
      }

      if (!message) {
        this.logError('Message not found');
        this.isTyping = false;
        return false;
      }
      this.pushMessage(threadId, message);

      const assistantMessage: ChatGPTMessage = {
        id: Date.now().toString(),
        role: 'assistant',
        content: '',
        attachments: { files: [], images: [] },
        created: Date.now(),
        language: appStore.locale,
        isProcessing: true,
      };
      this.pushMessage(threadId, assistantMessage);

      this.isTyping = true;
      try {
        const result = await aiAssistantHelper.getAnswerFromAssistant(
          this.assistant.id,
          threadId,
          message.content,
          files
        );
        if (!result?.assistantMessage) {
          return false;
        }

        this.updateMessage(message.id, {
          id: result.userMessage.id,
          attachments: {
            files:
              files?.map((f) => {
                return { file_id: f.id };
              }) ?? [],
            images: [],
          },
        });
        this.updateMessage(assistantMessage.id, {
          id: result.assistantMessage.id,
          attachments: {
            files: result.assistantMessage.attachments ?? [],
            images: [],
          },
          isProcessing: false,
        });

        const answerType = result.assistantMessage.content[0].type;
        const aiId = result.assistantMessage.id;
        switch (answerType) {
          case 'text':
            this.updateMessage(aiId, {
              content: result.assistantMessage.content[0].text.value,
            });
            break;
          case 'image_file':
            this.pushImage(threadId, {
              url: '',
              id: result.assistantMessage.content[0].image_file.file_id,
            });
            break;
          case 'image_url':
            this.pushImage(threadId, {
              url: result.assistantMessage.content[0].image_url.url,
              id: '',
            });
            break;
          default:
            break;
        }
        return true;
      } catch (error) {
        this.sendFailed = true;
        this.logError(String(error));
        return false;
      } finally {
        this.isTyping = false;
      }
    },
    // createImageVariations - takes images: PickedFile[] - returns ?
    // editImages - takes images: PickedFile[] and prompt - returns ?
    // generateImages - takes prompt - returns ?
    logError(error: string) {
      console.error(error);
    },
  },
  persist: true,
});
