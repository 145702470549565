import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/core';
import { toastController } from '@ionic/vue';
import type { AxiosError } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { refreshCircleOutline, alertCircleOutline } from 'ionicons/icons';

import { RequirementsEnum, ResponseErrorEnum } from '@/enums';
import { showToast, useUserFlow, useTokenHelper, useRequirementsHelper } from '@/helpers';
import { DEFAULT_LOCALE, useI18n, SUPPORT_LOCALES } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { useAppStore, useMessengerStore, useNetworkStore } from '@/store';
import type { ErrorMessageModel, ResponseErrorModel, ResponseVersionModel } from '@/types';

const { t } = useI18n();
const icons = {
  alert: alertCircleOutline,
  refresh: refreshCircleOutline,
};

const axiosInstance = axios.create({
  headers: {
    common: {
      'Accept-Language': SUPPORT_LOCALES.includes(window.navigator.language.substring(0, 2).toLowerCase())
        ? window.navigator.language.substring(0, 2).toLowerCase()
        : DEFAULT_LOCALE,
    },
  },
});

let retry = 0;

if (import.meta.env.VITE_AXIOS_RETRY_ENABLED === 'true') {
  axiosRetry(axiosInstance, {
    retries: Number(import.meta.env.VITE_AXIOS_RETRY_COUNT), // Количество попыток повтора
    retryDelay: (retryCount) =>
      retryCount === 1
        ? import.meta.env.VITE_AXIOS_RETRY_AFTER_FIRST_ERROR_DELAY
        : Number(import.meta.env.VITE_AXIOS_RETRY_AFTER_FIRST_ERROR_DELAY) / 2, // Задержка между повторами
    retryCondition: (error) =>
      error.request.responseType !== 'blob' &&
      error.config?.url !== undefined &&
      !error.config.url.startsWith('/oauth') &&
      error?.response?.status === 502,
    onRetry: async (retryCount, error) => {
      console.log('🚀 ~ axiosRetry --> repeat the query, attempt: ', retryCount, error);

      if (retryCount === 1) {
        await showToast(t('retryResponse'), false, false, icons.refresh);
      }

      if (retryCount === Number(import.meta.env.VITE_AXIOS_RETRY_COUNT)) {
        toastController.getTop().then((toast) => {
          return toast ? toastController.dismiss() : null;
        });

        if (error.response?.status !== 200) {
          console.error('Error in axiosRetry: ', error);
          return await showToast(t('errorResponse'), false, false, icons.alert);
        }
      }
    },
  });
}

/*
Действия перед отправкой запроса на сервер
*/
axiosInstance.interceptors.request.use(
  // Function to be executed before the request is sent
  async function (config: any) {
    const appStore = useAppStore();
    const tokenHelper = useTokenHelper();
    const controller = new AbortController();
    const dataAuth = await tokenHelper.getNewToken(false);
    config.baseURL = `${dataAuth.url}${import.meta.env.VITE_APP_URL_API}`;

    if (dataAuth.token !== null) {
      config.headers.Authorization = `${import.meta.env.VITE_APP_AUTHORIZATION_KEY} ${dataAuth.token}`;
    }

    config.headers.BrowserPlatforms = getPlatforms();
    config.headers.CapacitorPlatform = Capacitor.getPlatform();
    config.headers.IsNativePlatform = Capacitor.isNativePlatform() ? 'True' : 'False';
    config.headers.ApiVersion = import.meta.env.VITE_APP_API;

    if (dataAuth.token === null && !tokenHelper.isCodeExpired()) {
      console.error('Error in request interceptor - 1: token is null');
      controller.abort();
    }

    if (appStore.isAuth && tokenHelper.isCodeExpired()) {
      console.error('Error in request interceptor - 2: token is expired');
      controller.abort();
      await Promise.all([useUserFlow().logOut(), router.replace({ name: ROUTES_NAME.LOGIN })]);
    }

    return { ...config, signal: controller.signal };
  },

  // Function to be executed if the request fails
  async (e) => {
    toastController.getTop().then(async (n: HTMLIonToastElement) => {
      if (!n) {
        console.error('Error in request interceptor - 3: ', e);
        return showToast(e?.response?.data?.error?.message || t('errorResponse'), false, false, icons.alert);
      }
    });
    return;
  }
);

/*
Действия после получения ответа сервера
 */
axiosInstance.interceptors.response.use(
  async function (res: any) {
    if (res.status == 200) {
      let isCoreError = false;
      let eType = res.data.errorType;
      if (eType) {
        console.log('errorType: ' + eType);
      } else {
        eType = res.data.data?.errorType;
        if (eType) {
          console.log('errorType: ' + eType);
          isCoreError = true;
        }
      }

      if (eType === ResponseErrorEnum.invalidAcceptPolicy) {
        const appStore = useAppStore();
        const networkStore = useNetworkStore();
        const messengerStore = useMessengerStore();

        appStore.isLoading = false;
        networkStore.isLoading = false;
        messengerStore.isLoading = false;

        await useRequirementsHelper().check(RequirementsEnum.UsageRules);
        return axiosInstance.request(res.config);
      }

      if (eType === ResponseErrorEnum.accessDenied && retry < 3) {
        const tokenHelper = useTokenHelper();
        const dataAuth = await tokenHelper.getNewToken(true);
        retry++;

        if (retry >= 3) {
          return Object.assign(res.data, {
            statusCode: 500,
          });
        }
        if (dataAuth.token !== null && res.config.headers) {
          res.config.headers.Authorization = `${import.meta.env.VITE_APP_AUTHORIZATION_KEY} ${dataAuth.token}`;
        }

        return axiosInstance.request(res.config);
      } else {
        retry = 0;
      }

      if (isCoreError) {
        return res.data.data;
      }

      return Object.assign(res.data, {
        statusCode: eType ? 500 : 200,
      });
    } else {
      if (res.statusText) {
        toastController.getTop().then(async (n: HTMLIonToastElement) => {
          if (n) {
            return showToast(res.statusText, false, false, icons.alert);
          }
        });
      }
    }
  },
  async (e: AxiosError<any>) => {
    const networkStore = useNetworkStore();
    if (e.response?.data?.errorType === ResponseErrorEnum.accessDeniedForUser) {
      networkStore.isNetworkAvailable = false;
    }

    if (e.response?.data?.errorType === ResponseErrorEnum.invalidAcceptPolicy) {
      console.error('Error in response interceptor - 4: invalidAcceptPolicy');
      const appStore = useAppStore();
      const messengerStore = useMessengerStore();
      const requirementsHelper = useRequirementsHelper();

      appStore.isLoading = false;
      networkStore.isLoading = false;
      messengerStore.isLoading = false;
      await requirementsHelper.check(RequirementsEnum.UsageRules);

      return axiosInstance.request(e.response.config);
    }

    if (e.response?.status === 401) {
      const tokenHelper = useTokenHelper();
      await tokenHelper.getNewToken(true);
    }

    if (e?.response?.data == null) {
      console.error('Error in response interceptor - 5: response.data is null: ', e);
      return {
        statusCode: 500,
        errorType: 'error',
        errorMessages: [
          {
            key: 'default',
            errors: [t('errorResponse')],
          },
        ] as ErrorMessageModel[],
      } as ResponseErrorModel;
    }

    const errorMessages = e.response.data.errorMessages || undefined;
    if (errorMessages !== undefined && e.response.status !== 500) {
      console.error('Error in response interceptor - 6: ', errorMessages);
      return {
        statusCode: e.response.status,
        errorType: e.response.data.errorType ?? 'error',
        errorMessages: errorMessages,
      } as ResponseErrorModel;
    }

    const message =
      errorMessages === undefined
        ? e?.response?.data?.error?.message || t('errorResponse')
        : errorMessages[0].errors.join(';');
    if (e?.config?.responseType !== 'blob') {
      toastController.getTop().then(async (n: HTMLIonToastElement) => {
        if (!n) {
          if (e?.config?.headers?.withoutToast || import.meta.env.PROD) {
            return console.error('Error in response interceptor - 7: ', message);
          } else {
            // TODO: https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1487
            return showToast(message, false, false, icons.alert);
          }
        }
      });
    }

    console.error('Error in response interceptor - 8: ', message);
    return {
      statusCode: 500,
      errorType: 'error',
      errorMessages: [
        {
          key: 'default',
          errors: [message],
        },
      ] as ErrorMessageModel[],
    } as ResponseErrorModel;
  }
);

export async function getAppVersion(): Promise<string> {
  const appStore = useAppStore();
  let urlApp = '';

  const url = `${appStore.url}${import.meta.env.VITE_APP_URL_API}/tools/appVersion`;
  await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      ApiVersion: import.meta.env.VITE_APP_API,
    },
    body: JSON.stringify({
      version: appStore.appVersion,
      //?: build: appStore.appBuild,
    }),
  })
    .then(async (response: Response) => {
      if (response.ok) {
        await response.json().then((responseToken: ResponseVersionModel) => {
          urlApp = responseToken.data;
        });
      } else {
        console.log(response.status);
      }
    })
    .catch((e: any) => {
      console.log(e);
    });

  return urlApp;
}

export default axiosInstance;
