import type { OverlayEventDetail } from '@ionic/core';
import { alertController } from '@ionic/core';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { showToast } from './helper';

import { AppCardsActionEnum, DataViewMode, UserAccessEnum, UserRoleEnum, UserStatusEnum } from '@/enums';
import { useI18n } from '@/i18n';
import { useNetworkStore, useUserStore } from '@/store';
import type { AppActionButton, NetworkEntity, UserEntity } from '@/types';

interface IUsers {
  getMainUserAction(
    userData: UserEntity,
    mode: DataViewMode
  ): {
    left: AppActionButton;
    right: AppActionButton;
  };
  onFollow(id: number): Promise<void>;
  onUnfollow(id: number): Promise<void>;
  getUserSubtitle(position: string, subDivision: string): string;
  getUserRoleTitleByRoleId(roleId: UserRoleEnum): {
    title: string;
    description: string;
  };
  getUserRolesList(): {
    title: string;
    description: string;
    roleId: UserRoleEnum;
  }[];
  getUserAccessList(userId: number): {
    title: string;
    description: string;
    value: UserAccessEnum;
  }[];
  userAccessAction(userId: number, action: UserAccessEnum): Promise<boolean>;
  getAccessStatus(userId: number): UserAccessEnum;
}

export function useUsersHelper(): IUsers {
  const onFollow = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const userStore = useUserStore();
    if (await userStore.onFollow(id)) {
      await showToast(t('subscribe.subscribedToUser'), true);
    }
  };

  const onUnfollow = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const userStore = useUserStore();

    const result = await unFollowConfirm();
    if (result) {
      if (await userStore.unFollow(id)) {
        await showToast(t('subscribe.unsubscribedFromUser'), true);
      }
    }
  };

  const unFollowConfirm = async (): Promise<boolean> => {
    const { t } = useI18n();
    const alert = await alertController.create({
      message: t('subscribe.unfollowConfirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert_buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert_buttons',
          role: 'confirm',
        },
      ],
    });

    await alert.present();

    return alert.onDidDismiss().then(async (result: OverlayEventDetail<boolean>) => {
      return result.role === 'confirm';
    });
  };

  const getMainUserAction = (
    userData: UserEntity,
    mode: DataViewMode
  ): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const userStore = useUserStore();
    const currentUserId = () => userStore.current?.id ?? 0;
    const isCurrentUser = () => userData.id === currentUserId();

    const isFollowedByCurrentUser = (): boolean => userData.isFollowedByCurrentUser && !isCurrentUser();

    const defaultAction: AppActionButton = {
      title: '',
      type: 'main',
      action: AppCardsActionEnum.None,
      icon: '',
      showIcon: false,
      showTooltip: false,
      showTitle: false,
    };

    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: { ...defaultAction },
      right: { ...defaultAction },
    };

    if (isFollowedByCurrentUser()) {
      actionData.left = {
        ...defaultAction,
        title: t('subscribe.unfollow'),
        type: 'secondary',
        action: AppCardsActionEnum.UserUnfollow,
        icon: 'userDeleteCross',
        showTitle: mode === DataViewMode.Grid,
      };
      actionData.right = {
        ...defaultAction,
        title: t('open'),
        action: AppCardsActionEnum.Open,
      };
    } else if (isCurrentUser()) {
      actionData.right = {
        ...defaultAction,
        title: t('account.title'),
        type: 'secondary',
        action: AppCardsActionEnum.Open,
        showTitle: mode === DataViewMode.Grid,
      };
    } else {
      actionData.right = {
        ...defaultAction,
        title: t('subscribe.follow'),
        action: AppCardsActionEnum.UserFollow,
        icon: 'userCheck',
        showTitle: true,
      };
    }

    return actionData;
  };

  const getUserSubtitle = (position: string, subDivision: string): string => {
    return `${subDivision ? subDivision : ''}${position && subDivision ? ', ' : ''}${position ? position : ''}`;
    return '';
  };

  const getUserRoleTitleByRoleId = (
    roleId: UserRoleEnum
  ): { title: string; description: string; roleId: UserRoleEnum } => {
    const { t } = useI18n();
    switch (roleId) {
      case UserRoleEnum.ExternalGroupUserReadLike:
        return {
          title: t('userPage.infoList.roles.externalGroupUserReadLike.title'),
          description: t('userPage.infoList.roles.externalGroupUserReadLike.description'),
          roleId: UserRoleEnum.ExternalGroupUserReadLike,
        };
      case UserRoleEnum.ExternalGroupUserLikeUpsert:
        return {
          title: t('userPage.infoList.roles.externalGroupUserLikeUpsert.title'),
          description: t('userPage.infoList.roles.externalGroupUserLikeUpsert.description'),
          roleId: UserRoleEnum.ExternalGroupUserLikeUpsert,
        };
      case UserRoleEnum.ExternalGroupUser:
        return {
          title: t('userPage.infoList.roles.externalGroupUser.title'),
          description: t('userPage.infoList.roles.externalGroupUser.description'),
          roleId: UserRoleEnum.ExternalGroupUser,
        };
      case UserRoleEnum.UserReadLike:
        return {
          title: t('userPage.infoList.roles.userReadLike.title'),
          description: t('userPage.infoList.roles.userReadLike.description'),
          roleId: UserRoleEnum.UserReadLike,
        };
      case UserRoleEnum.UserLikeUpsert:
        return {
          title: t('userPage.infoList.roles.userLikeUpsert.title'),
          description: t('userPage.infoList.roles.userLikeUpsert.description'),
          roleId: UserRoleEnum.UserLikeUpsert,
        };
      case UserRoleEnum.User:
        return {
          title: t('userPage.infoList.roles.user.title'),
          description: t('userPage.infoList.roles.user.description'),
          roleId: UserRoleEnum.User,
        };
      case UserRoleEnum.Expert:
        return {
          title: t('userPage.infoList.roles.expert.title'),
          description: t('userPage.infoList.roles.expert.description'),
          roleId: UserRoleEnum.Expert,
        };
      case UserRoleEnum.BadgesManager:
        return {
          title: t('userPage.infoList.roles.badgesManager.title'),
          description: t('userPage.infoList.roles.badgesManager.description'),
          roleId: UserRoleEnum.BadgesManager,
        };
      case UserRoleEnum.InnovationManager:
        return {
          title: t('userPage.infoList.roles.innovationManager.title'),
          description: t('userPage.infoList.roles.innovationManager.description'),
          roleId: UserRoleEnum.InnovationManager,
        };
      case UserRoleEnum.LightModerator:
        return {
          title: t('userPage.infoList.roles.lightModerator.title'),
          description: t('userPage.infoList.roles.lightModerator.description'),
          roleId: UserRoleEnum.LightModerator,
        };
      case UserRoleEnum.Moderator:
        return {
          title: t('userPage.infoList.roles.moderator.title'),
          description: t('userPage.infoList.roles.moderator.description'),
          roleId: UserRoleEnum.Moderator,
        };
      case UserRoleEnum.FreeExtNetAdministrator:
        return {
          title: t('userPage.infoList.roles.freeExtNetAdministrator'),
          description: '',
          roleId: UserRoleEnum.FreeExtNetAdministrator,
        };
      case UserRoleEnum.Administrator:
        return {
          title: t('userPage.infoList.roles.administrator.title'),
          description: t('userPage.infoList.roles.administrator.description'),
          roleId: UserRoleEnum.Administrator,
        };
      case UserRoleEnum.SuperAdministrator:
        return {
          title: t('userPage.infoList.roles.superAdministrator.title'),
          description: t('userPage.infoList.roles.superAdministrator.description'),
          roleId: UserRoleEnum.SuperAdministrator,
        };
      case UserRoleEnum.SecurityManager:
        return {
          title: t('userPage.infoList.roles.securityManager'),
          description: '',
          roleId: UserRoleEnum.SecurityManager,
        };
      case UserRoleEnum.System:
        return {
          title: t('userPage.infoList.roles.system'),
          description: '',
          roleId: UserRoleEnum.System,
        };
      case UserRoleEnum.TenantInstanceManager:
        return {
          title: t('userPage.infoList.roles.tenantInstanceManager'),
          description: '',
          roleId: UserRoleEnum.TenantInstanceManager,
        };
      case UserRoleEnum.InstanceManager:
        return {
          title: t('userPage.infoList.roles.instanceManager'),
          description: '',
          roleId: UserRoleEnum.InstanceManager,
        };
      default:
        return {
          title: '',
          description: '',
          roleId: UserRoleEnum.User,
        };
    }
  };

  const getUserRolesList = () => {
    const rolesList = [];
    const userStore = useUserStore();
    const currentUserRoleId = (): UserRoleEnum => userStore.current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    for (const role in UserRoleEnum) {
      if (isNaN(Number(role))) {
        const roleId = UserRoleEnum[role as keyof typeof UserRoleEnum];
        if (roleId <= currentUserRoleId()) {
          if (
            roleId !== UserRoleEnum.FreeExtNetAdministrator &&
            roleId !== UserRoleEnum.LightModerator &&
            roleId !== UserRoleEnum.SecurityManager &&
            roleId !== UserRoleEnum.TenantInstanceManager
          ) {
            rolesList.push(getUserRoleTitleByRoleId(roleId));
          }
        }
      }
    }
    return rolesList;
  };

  const getUserAccessList = (userId: number) => {
    const userStore = useUserStore();
    const networkStore = useNetworkStore();
    const currentNetwork: ComputedRef<NetworkEntity> = computed(() => networkStore.getNetwork);
    const user: ComputedRef<UserEntity> = computed(() => userStore.getUserProfile(userId));
    const { t } = useI18n();

    const currentStatus = getAccessStatus(userId);
    return [
      {
        title: t('userPage.infoList.access.activate.title'),
        description: t('userPage.infoList.access.activate.description'),
        value: UserAccessEnum.Activate,
        enabled: !user.value.isActive && currentStatus !== UserAccessEnum.Block,
      },
      {
        title: t('userPage.infoList.access.deactivate.title'),
        description: t('userPage.infoList.access.deactivate.description'),
        value: UserAccessEnum.Deactivate,
        enabled: user.value.isActive,
      },
      {
        title: t('userPage.infoList.access.block.title'),
        description: t('userPage.infoList.access.block.description', {
          n: currentNetwork.value.title,
        }),
        value: UserAccessEnum.Block,
        enabled: currentStatus === UserAccessEnum.None,
      },
      {
        title: t('userPage.infoList.access.unblock.title'),
        description: t('userPage.infoList.access.unblock.description', {
          n: currentNetwork.value.title,
        }),
        value: UserAccessEnum.Unblock,
        enabled: currentStatus === UserAccessEnum.Block,
      },
      {
        title: t('userPage.infoList.access.delete.title'),
        description: t('userPage.infoList.access.delete.description'),
        value: UserAccessEnum.Delete,
        enabled: currentStatus === UserAccessEnum.None,
      },
    ].filter((item) => item.enabled);
  };

  const getAccessStatus = (userId: number): UserAccessEnum => {
    const userStore = useUserStore();
    const user: ComputedRef<UserEntity> = computed(() => userStore.getUserProfile(userId));

    if (user.value.status === UserStatusEnum.Banned) return UserAccessEnum.Block;

    if (user.value.status === UserStatusEnum.Deleted) return UserAccessEnum.Delete;

    if (!user.value.isActive) return UserAccessEnum.Deactivate;

    return UserAccessEnum.None;
  };

  const userAccessAction = async (userId: number, action: UserAccessEnum): Promise<boolean> => {
    const { t } = useI18n();
    const userStore = useUserStore();
    switch (action) {
      case UserAccessEnum.Activate: {
        const result = await userStore.changeUserActivation(userId, true);
        if (result) {
          showToast(t('userPage.infoList.access.activate.success'), true);
        } else {
          showToast(t('userPage.infoList.access.activate.error'), false);
        }
        return result;
      }

      case UserAccessEnum.Deactivate: {
        const result = await userStore.changeUserActivation(userId, false);
        if (result) {
          showToast(t('userPage.infoList.access.deactivate.success'), true);
        } else {
          showToast(t('userPage.infoList.access.deactivate.error'), false);
        }
        return result;
      }

      case UserAccessEnum.Block: {
        const result = await userStore.changeUserAccessStatus(userId, true);
        if (result) {
          showToast(t('userPage.infoList.access.block.success'), true);
        } else {
          showToast(t('userPage.infoList.access.block.error'), false);
        }
        return result;
      }

      case UserAccessEnum.Unblock:
        {
          const result = await userStore.changeUserAccessStatus(userId, false);
          if (result) {
            showToast(t('userPage.infoList.access.unblock.success'), true);
          } else {
            showToast(t('userPage.infoList.access.unblock.error'), false);
          }
          return result;
        }
        break;

      case UserAccessEnum.Delete: {
        const result = await userStore.deleteUser(userId);
        if (result) {
          showToast(t('userPage.infoList.access.delete.success'), true);
        } else {
          showToast(t('userPage.infoList.access.delete.error'), false);
        }
        return result;
      }

      default:
        return false;
    }
  };

  return {
    getMainUserAction,
    onFollow,
    onUnfollow,
    getUserSubtitle,
    getUserRoleTitleByRoleId,
    getUserRolesList,
    getUserAccessList,
    userAccessAction,
    getAccessStatus,
  };
}
