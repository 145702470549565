<template>
  <section>
    <!-- NOTE: The list of files displayed at the top or on the entire of the drag and drop area -->
    <div
      v-if="files.length > 0 && !isPublisher"
      class="files-list"
      :class="isSingle ? 'ion-justify-content-center' : ''"
    >
      <close-block-wrapper
        v-for="item in files"
        :key="item.key"
        :class="['file-wrapper', { 'with-margin': !isSingle }]"
        :is-single-image="isSingle"
        @onAction="onDeleteFile(item)"
      >
        <file-icon-preview :payload="item.payload" />
      </close-block-wrapper>
    </div>

    <div
      v-if="!(isSingle && files.length > 0)"
      class="drag-zone"
      :class="[dragActive ? 'active' : '', isPublisher ? 'is-publisher' : '']"
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="toggleActive"
      @click="attachActionSheet()"
    >
      <icons-provider
        :icon-props="{
          width: '32',
          height: '32',
          fill: 'var(--ion-color-medium)',
        }"
        :name="iconName"
      />

      <ion-label>
        {{ dragLabel }}
        <p v-if="recommendedSize">
          {{
            $t('files.dragZone.recommended', {
              extensions: recommendedSize.extensions,
              ratio: recommendedSize.ratio,
            })
          }}
        </p>
      </ion-label>

      <!-- NOTE: When files are displayed in the center of the drag and drop area -->
      <div v-if="files.length > 0 && isPublisher" class="files-list ion-justify-content-center">
        <close-block-wrapper
          v-for="item in files"
          :key="item.key"
          :class="['file-wrapper', { 'with-margin': !isSingle }]"
          :is-single-image="isSingle"
          :with-close="!isPublisher"
          @onAction="onDeleteFile(item)"
        >
          <file-icon-preview :payload="item.payload" />
        </close-block-wrapper>
      </div>

      <!-- NOTE: Drag and drop area closure button -->
      <div v-if="isPublisher" class="close" @click.stop="onClose">
        <ion-button fill="clear">
          <icons-provider
            slot="icon-only"
            :icon-props="{
              width: '18',
              height: '18',
              fill: 'var(--ion-color-medium)',
            }"
            :name="AppIconsEnum.Close"
          />
        </ion-button>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { IonLabel, IonButton } from '@ionic/vue';
import { watchThrottled } from '@vueuse/core';
import type { ComputedRef, PropType } from 'vue';
import { ref, watch, onUnmounted, computed } from 'vue';

import { CloseBlockWrapper, FileIconPreview, IconsProvider } from '@/components';
import { AppIconsEnum, AvatarTypeEnum, UploadFileTypes } from '@/enums';
import { componentAvatarChange, filesHybrid, showToast } from '@/helpers';
import { useI18n } from '@/i18n';
import type { FileModel } from '@/types';

const props = defineProps({
  fileTypes: {
    type: Number as PropType<UploadFileTypes>,
    required: true,
  },
  recommendedSize: {
    type: Object as PropType<{ extensions: string; ratio: string }>,
    default: null,
  },
  withCropper: {
    type: Boolean,
    default: false,
  },
  isPublisher: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['fileIsLoading', 'files', 'allFilesIsLoaded']);

const { t } = useI18n();
const files = ref<FileModel[]>([]);
const iterationFileCount = ref(0);
const uploadIsActive = ref(true);
const dragActive = ref<boolean>(false);

const isSingle: ComputedRef<boolean> = computed(
  () =>
    props.fileTypes === UploadFileTypes.SingleAnyFile ||
    props.fileTypes === UploadFileTypes.SingleImage ||
    props.fileTypes === UploadFileTypes.SingleVideo
);
const isLoading: ComputedRef<boolean> = computed(() => filesHybrid.isLoading.value);
const iconName: ComputedRef<string> = computed(() =>
  isLoading.value
    ? AppIconsEnum.CircleAnim
    : props.fileTypes !== UploadFileTypes.SingleImage
      ? AppIconsEnum.Documents
      : AppIconsEnum.DocImage
);
const dragLabel: ComputedRef<string> = computed(() =>
  props.fileTypes !== UploadFileTypes.SingleImage ? t('files.dragZone.file') : t('files.dragZone.image')
);

const toggleActive = async (e: DragEvent) => {
  if (e.dataTransfer) {
    iterationFileCount.value = iterationFileCount.value + e.dataTransfer.files.length;

    for (const element of e.dataTransfer.files) {
      if (uploadIsActive.value) {
        const uploadFile = await filesHybrid.uploadWeb(element);
        if (uploadFile !== undefined) {
          files.value.push(uploadFile);
        }
      }

      iterationFileCount.value = iterationFileCount.value - 1;
    }

    dragActive.value = !dragActive.value;
  }
};

const attachActionSheet = async () => {
  // Picking file
  const attachFiles = await filesHybrid.pickFiles(props.fileTypes);

  // Cropping file
  if (props.withCropper) {
    for (const file of attachFiles) {
      if (file.blob) {
        const croppedFile = await componentAvatarChange(null, file.blob, AvatarTypeEnum.NetworkLogo);
        if (croppedFile.data) file.blob = croppedFile.data;
      }
    }
  }

  // Saving file
  if (attachFiles.length > 0) {
    const attached = [];
    for (let i = 0; i < attachFiles.length; i++) {
      const _file = await filesHybrid.toFile(attachFiles[i]);
      if (_file !== undefined) {
        files.value.push(_file);
        attached.push({
          index: i,
          payload: _file.payload,
        });
      } else {
        await showToast(
          t('uploadFileIsLarger', {
            size: import.meta.env.VITE_APP_MAX_SIZE,
          }),
          false
        );
      }
    }

    // Uploading file
    for (const file of attached) {
      const uploadFile = await filesHybrid.uploadFile(attachFiles[file.index]);
      const index = files.value.findIndex((f) => f.payload === file.payload);
      if (~index && uploadFile !== undefined) {
        files.value.splice(index, 1, uploadFile);
      }
    }
  }
};

const onDeleteFile = async (file: FileModel) => {
  files.value = files.value.filter((f: FileModel) => f.payload !== file.payload);
  iterationFileCount.value = files.value.length;
  await filesHybrid.remove(file.key);

  if (files.value.length === 0) {
    onClose();
  }
};

const onClose = () => {
  files.value = [];
  uploadIsActive.value = false;
  iterationFileCount.value = 0;

  emit('allFilesIsLoaded', true);
};

watch(isLoading, () => {
  emit('fileIsLoading', isLoading.value);
});

watchThrottled(
  isLoading,
  () => {
    if (iterationFileCount.value === 0 && !isLoading.value) {
      emit('allFilesIsLoaded', true);
    } else {
      emit('allFilesIsLoaded', false);
    }
  },
  { throttle: 1000 }
);

watch(
  files,
  async () => {
    emit(
      'files',
      files.value.filter((f: FileModel) => f.key !== '')
    );
  },
  { deep: true }
);

onUnmounted(() => {
  files.value = [];
});
</script>

<style scoped lang="scss">
.drag-zone {
  min-height: 6rem;
  width: 100%;
  border: 2px dashed var(--ion-color-custom-element-darker);
  border-radius: app-radius(md);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  overflow: hidden;

  ion-label {
    color: var(--ion-color-medium);
    margin-top: app-padding(md);
    p {
      font-size: 0.8rem;
      color: var(--ion-color-medium);
    }
  }

  ion-icon {
    font-size: 2rem;
    color: var(--ion-color-medium);
  }

  &:hover {
    cursor: pointer;
    background: var(--ion-color-light);
  }

  &.active {
    background: var(--ion-color-light);
  }

  &.is-publisher {
    background: var(--ion-color-light);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    ion-button {
      @include resetStyleFromIonicButton();

      & {
        --border-radius: #{app-radius(md)};
        margin-top: app-padding(md);
        margin-right: app-padding(md);
      }
    }
  }
}

.files-list {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.file-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  &.with-margin {
    margin-right: app-padding(lg);
    margin-bottom: app-padding(lg);
  }

  file-info {
    justify-content: start;
    align-items: start;
  }
}
</style>
