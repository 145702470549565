<template>
  <div v-if="users.length > 0" class="list-block">
    <ion-list class="list">
      <ion-item v-for="item in users" :key="item.id" @click.stop="onChoose(item.mainAlias)">
        <user-list-item :user="item" :with-alias="true" />
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts" setup>
import { IonList, IonItem } from '@ionic/vue';
import { watchDebounced } from '@vueuse/core';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { UserListItem } from '@/components';
import { UsersFilterEnum } from '@/enums';
import { useUserStore } from '@/store';
import type { UserEntity } from '@/types';

const userStore = useUserStore();

const props = defineProps({
  caret: {
    type: Number,
    required: true,
    default: () => 0,
  },
  message: {
    type: String,
    required: true,
    default: () => '',
  },
});
const emit = defineEmits(['chooseUser']);
const users: ComputedRef<UserEntity[]> = computed(() => userStore.getUsersChosen(UsersFilterEnum.ChosenMentions).data);
let index = -1;

const caret: ComputedRef<number> = computed(() => props.caret);

watchDebounced(
  caret,
  async () => {
    const message = props.caret < props.message.length ? props.message.substring(0, props.caret) : props.message;
    index = message.lastIndexOf('@');
    if (~index) {
      if (message.substring(index + 1) !== '' && !message.substring(index + 1).includes(' ')) {
        await userStore.chooseUserAutocomplete(message.substring(index + 1), UsersFilterEnum.ChosenMentions);
      } else {
        userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenMentions);
      }
    } else {
      userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenMentions);
    }
  },
  { debounce: 1000, maxWait: 1500 }
);

const onChoose = async (alias: string) => {
  emit('chooseUser', { alias, index });
  userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenMentions);
};
</script>

<style scoped lang="scss">
.list-block {
  border: 1px solid var(--ion-color-custom-element-lighter);
  border-radius: 0 0 app-radius(md) app-radius(md);
  border-top: none;
  position: relative;
  bottom: 0.8rem;
  overflow: hidden;
  z-index: 1;
  background: var(--ion-color-light-background-contrast);
}
.list {
  max-height: 210px;
  overflow-y: scroll;
  padding-inline: 1rem;
  margin: 0;
  background: var(--ion-color-light-background-contrast);
}
.list::-webkit-scrollbar {
  width: 6px;
}
.list::-webkit-scrollbar-track {
  background-color: var(--ion-color-custom-element-lighter);
  border-radius: 3px;
}
.list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: var(--ion-color-custom-element-darker);
}
ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --background: rgba(255, 255, 255, 0);
}
ion-item:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>
