<template>
  <div class="wiki">
    <div
      v-for="(item, index) in finalWikiData"
      :key="`wiki_${index}`"
      class="wiki-block"
      @click.stop="openWiki(item.id, item.isOutdated)"
    >
      <div class="left_block">
        <icons-provider
          :icon-props="{
            width: '40',
            height: '40',
          }"
          :name="'wiki'"
          class="icon"
        />
        <ion-button
          v-if="item.isOutdated"
          v-tooltip="t('wiki.editOptions.outdatedExplanation')"
          class="outdated"
          size="small"
          @click="onOutdatedClick"
        >
          {{ t('wiki.editOptions.outdated') }}
        </ion-button>
      </div>

      <div class="info">
        <ion-label class="title">{{ item.name }} </ion-label>
        <ion-text class="text" v-html="getInfo(item)" />
      </div>

      <div class="right_block">
        <ion-button
          v-if="status !== FileStatusEnum.Loading"
          size="default"
          fill="clear"
          class="download-button"
          @click.stop="downloadWiki(item)"
        >
          <ion-icon slot="icon-only" :icon="downloadOutline" />
        </ion-button>
        <ion-button
          v-if="status === FileStatusEnum.Loading"
          size="default"
          fill="clear"
          class="download-button"
          @click.stop="stopDownload()"
        >
          <ion-icon slot="icon-only" :icon="closeOutline" />
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonLabel, IonButton, IonIcon } from '@ionic/vue';
import { closeOutline, downloadOutline } from 'ionicons/icons';
import type { PropType, Ref } from 'vue';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import { IconsProvider } from '@/components';
import { WikiVersionEnum, FileStatusEnum, DocumentExtensionEnum, WikiEditControlsEnum } from '@/enums';
import { filesHybrid, showToast, isBlob, useWiki } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useWikiStore } from '@/store';
import type { WikiModel } from '@/types';

// Props
const props = defineProps({
  wikiData: {
    type: Object as PropType<WikiModel[]>,
    required: true,
  },
  createdAt: {
    type: String,
    required: true,
  },
});

interface WikiData extends WikiModel {
  isOutdated: boolean;
}

// Router
const router = useRouter();

// Helpers
const { t } = useI18n();
const wikiHelper = useWiki();

// Store
const wikiStore = useWikiStore();

// Refs
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);
const finalWikiData: Ref<WikiData[]> = ref<WikiData[]>([]);

// Actions
const getInfo = (item: WikiModel) => {
  if (item.version === WikiVersionEnum.V1) {
    return item.bodyHtml;
  } else if (item.version === WikiVersionEnum.V2) {
    const info = [] as string[];
    item.wikiContent.body.forEach((element) => {
      info.push(element.name);
    });
    return info;
  } else {
    return '';
  }
};

const openWiki = async (id: number, isOutdated: boolean) => {
  if (isOutdated) {
    wikiHelper.goToHistoricalWiki(id, props.createdAt);
    return;
  }

  await router.push({
    name: ROUTES_NAME.WIKI_BY_ID,
    params: { id: id },
  });
};

const downloadWiki = async (item: WikiModel) => {
  if (status.value === FileStatusEnum.Loading) {
    return;
  }

  status.value = FileStatusEnum.Loading;
  emit('onLoading', true, item.id);

  const response = await wikiStore.download(item.id, DocumentExtensionEnum.PDF);
  if (isBlob(response)) {
    status.value = await filesHybrid.downloadWiki(item, response as Blob);
    await showToast(t('files.successDownloaded'), status.value === FileStatusEnum.Success);
  }

  await showToast(t('files.successDownloaded'), status.value === FileStatusEnum.Success);
};

const stopDownload = async () => {
  status.value = FileStatusEnum.Success;
  await showToast(t('files.downloadStopped'), true);
};

const onOutdatedClick = async (): Promise<void> => {
  wikiHelper.editControlsAction(WikiEditControlsEnum.GoToCurrentVersion);
};

// Watchers

// Lifecycle
onBeforeMount(async () => {
  // console.log('≥≥≥FeedWiki before mount'); //! DEBUG
  props.wikiData.forEach(async (wiki) => {
    const isOutdated = await wikiHelper.checkIfOutdated(wiki, props.createdAt);
    finalWikiData.value.push({ ...wiki, isOutdated });
  });
});

// Emits
const emit = defineEmits(['onLoading']);
</script>

<style scoped lang="scss">
.wiki {
  margin-top: 1rem;

  .wiki-block {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    padding-right: 0;
    border: 1px solid var(--ion-color-light-custom);
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .left_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        flex-shrink: 0;

        ion-icon {
          font-size: 2.5rem;
          width: 100%;
        }
      }

      .outdated {
        @include resetStyleFromIonicButton;
        text-transform: unset;
        font-size: 0.5rem;
        width: 40px;
        --color: var(--ion-color-custom);
        --border-radius: 4px;
        --background: var(--ion-color-notification-badge);
      }
    }

    .info {
      margin-left: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      ion-text {
        --webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--ion-color-medium);
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    .right_block {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 0;

      .download-button {
        ion-icon {
          color: var(--ion-color-medium);
        }
      }
    }
  }
}
</style>
