import type { Component } from 'vue';
import { markRaw } from 'vue';

import type { AppIconsEnum } from '@/enums';

type IconComponent = {
  default: Component;
};

export type IIcons = {
  /**
   * @description Loads an icon by its name.
   */
  loadIcon: (iconName: AppIconsEnum) => Promise<Component | null>;

  /**
   * @description Get an array of icon file names.
   */
  getIconFileNames: () => AppIconsEnum[];
};

let instance: IIcons | null = null;

/**
 * @description Hook to use icons within the application to load icons by their name, get an array of icon file names, and preload all icons.
 */
export const useIcons = (): IIcons => {
  if (instance) {
    return instance;
  }

  /**
   * @description Glob import for icons
   * @type {Record<string, () => Promise<{ default: Component }>>}
   * @example { '../icons/home.vue': () => import('../icons/home.vue') }
   */
  const icons = import.meta.glob('../icons/**/*.vue') as Record<string, () => Promise<IconComponent>>;

  /**
   * @description Map of icon names to their respective paths
   * @type {Record<string, string>}
   * @example { 'home': '../icons/home.vue' }
   */
  const iconMap: Record<string, string> = {};

  //NOTE: Preload all icons/gitlab icons by populating the iconMap/gitlabIconMap with icon names and paths.
  const _preloadIcons = async (): Promise<void> => {
    for (const path in icons) {
      const fileName = path.split('/').pop()?.replace('.vue', '').toLowerCase();
      if (fileName) {
        iconMap[fileName] = path;
      } else {
        console.error('Error parsing icon file name:', path);
      }
    }
  };

  //NOTE: Office MIME type
  const officeMimeTypes = [
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  //NOTE: Extensions for Office files
  const docExtensions = ['docx', 'doc', 'docm', officeMimeTypes[0]];
  const excelExtensions = ['xlsx', 'xls', 'xlsm', officeMimeTypes[1]];
  const pptExtensions = ['pptx', 'ppt', 'pptm', officeMimeTypes[2]];

  //NOTE: Methods for checking extensions
  const _isDoc = (ext: string): boolean => docExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  const _isExcel = (ext: string): boolean => excelExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  const _isPpt = (ext: string): boolean => pptExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  /* NOTE: Check if the given extension is an office document */
  const _isOffice = (ext: string): boolean => _isDoc(ext) || _isExcel(ext) || _isPpt(ext);

  const loadIcon = async (iconName: AppIconsEnum): Promise<Component | null> => {
    if (!iconName) {
      console.error('No icon name provided.');
      return null;
    }

    let selectedIcon: Component | null = null;

    //NOTE: Check if office document icon
    if (_isOffice(iconName)) {
      switch (true) {
        case _isDoc(iconName):
          selectedIcon = markRaw((await import('../icons/FileExtensions/doc.vue')).default);
          break;
        case _isExcel(iconName):
          selectedIcon = markRaw((await import('../icons/FileExtensions/xlsx.vue')).default);
          break;
        case _isPpt(iconName):
          selectedIcon = markRaw((await import('../icons/FileExtensions/ppt.vue')).default);
          break;
        default:
          selectedIcon = null;
          break;
      }
    } else {
      const iconPath = iconMap[iconName];
      if (iconPath) {
        try {
          const iconModule = await icons[iconPath]();
          selectedIcon = markRaw(iconModule.default);
        } catch (error) {
          console.error('Error loading icon:', error);
          selectedIcon = null;
        }
      } else {
        console.error(`Icon file not found for the given prop name: ${iconName}`);
        selectedIcon = null;
      }
    }

    return selectedIcon;
  };

  const getIconFileNames = (): AppIconsEnum[] => {
    return Object.keys(icons).map((path) => path.split('/').pop()?.replace('.vue', '') || '') as AppIconsEnum[];
  };

  /* NOTE: Preload all icons from src/icons/ directory */
  _preloadIcons();

  instance = {
    loadIcon,
    getIconFileNames,
  };

  return instance;
};
