<template>
  <div :class="['card-tags', { 'with-margin': withMargin, 'is-post': isPost }]">
    <ion-chip
      v-for="tag in tags"
      :key="tag.id"
      :title="tag.title"
      :style="{
        '--background': tag.color ? convertObjectToRGB(tag.color) : 'var(--ion-color-custom-element-lighter)',
      }"
      :class="[size, { 'is-single': isSingleItem, 'is-vertical': isVertical }]"
      @click.stop="openTag(tag.id)"
    >
      <span
        :style="
          isPost
            ? ''
            : {
                color: tag.color ? getColor(convertObjectToRGB(tag.color)) : 'var(--ion-color-dark)',
              }
        "
        >{{ tag.title }}</span
      >
    </ion-chip>
  </div>
</template>

<script lang="ts" setup>
import { IonChip } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { ThemeAppEnum } from '@/enums';
import { LightOrDark, convertObjectToRGB } from '@/helpers';
import { ROUTES_NAME } from '@/router';
import { useTopicStore } from '@/store';
import type { TopicEntity } from '@/types';

const props = defineProps({
  tagsIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: false,
  },
  size: {
    type: String,
    default: 'small',
  },
  withMargin: {
    type: Boolean,
    default: true,
  },
  isSingleItem: {
    type: Boolean,
    default: false,
  },
  isVertical: {
    type: Boolean,
    default: false,
  },
  isPost: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();
const topicStore = useTopicStore();
const tags: ComputedRef<TopicEntity[] | []> = computed(() => topicStore.getTopicsByIds(props.tagsIds));

const getColor = (color: string | null) => {
  if (color) {
    return LightOrDark.getColor(color) === ThemeAppEnum.Light ? '#000000' : '#ffffff';
  }
  return '#ffffff';
};

const openTag = async (tagId: number) => {
  if (props.isActive) {
    await router.push({
      name: ROUTES_NAME.TOPIC_BY_ID,
      params: { id: tagId },
    });
  }
};
</script>

<style scoped lang="scss">
.card-tags {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  &.with-margin {
    margin-bottom: app-padding(md);
  }
  ion-chip {
    font-size: 0.8rem;
    padding: 4px 6px 4px 6px;
    height: unset;
    max-width: 120px;
    margin-inline: unset;
    margin-right: 2px;
    margin-bottom: 0;
    min-height: unset;

    &.medium {
      height: 32px;
      padding: 4px 12px 4px 12px;
      font-size: 0.9rem;
    }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: white;
    }
    &:hover {
      opacity: 0.7;
    }
    &.is-single {
      margin: 0;
    }
    &.is-vertical {
      padding: 12px 7px;
      margin-inline: unset;
      writing-mode: vertical-rl;
      height: unset;
    }
  }

  &.is-post {
    ion-chip {
      border: 1px solid var(--ion-color-custom-element-darker);
      background: unset;
      border-radius: 4px;
      padding: 2px 4px 2px 4px;
      font-size: 0.8rem;
      span {
        color: var(--ion-color-dark);
      }
      &.medium {
        font-size: 0.8rem;
        height: 32px;
        padding: 4px 12px 4px 12px;
      }
      &.is-vertical {
        padding: 12px 7px;
        margin-inline: unset;
        writing-mode: vertical-rl;
        height: unset;
      }
    }
  }

  @include respond-to-min-width(2xl) {
    ion-chip {
      font-size: 1rem;

      &.medium {
        font-size: 1rem;
      }
    }
  }
}
</style>
